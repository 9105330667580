import { Button, Grid } from '@mui/material';
import { useMemo, memo } from 'react';
import useTenantConfigData from 'src/hooks/useTenantConfigData';
import { WrappedPatient } from '../../../../@nicheaim/fhir-base/wrappers/Patient';
import fhirpath from 'fhirpath';
interface ExternalLinkProps {
  externalLinkKeysForData: string[];
  patient?: WrappedPatient | null;
}

function ExternalLink({ externalLinkKeysForData, patient }: ExternalLinkProps) {
  const { componentsData } = useTenantConfigData();

  const linksData = useMemo(() => {
    const links = externalLinkKeysForData
      .filter(
        (key) =>
          componentsData.externalLink?.[key]?.buttonTitle && componentsData.externalLink?.[key]?.url
      )
      ?.map((key) => componentsData.externalLink?.[key]);

    let formattedLinks = links.map((link) => {
      if (patient && link?.url && link?.substitutions) {
        try {
          let newUrl = `${link?.url}`;
          for (const [key, value] of Object.entries(link?.substitutions)) {
            const dataFromResource = fhirpath.evaluate(patient, value)?.[0];
            newUrl = newUrl.replace(key, dataFromResource);
            if (key && value && dataFromResource) {
              return {
                ...link,
                url: newUrl,
              };
            } else {
              return {
                ...link,
                url: link?.fallbackUrl,
              };
            }
          }
        } catch (error) {
          console.log(error);
          return {
            ...link,
            url: link?.fallbackUrl,
          };
        }
        return link;
      } else {
        return link;
      }
    });

    return formattedLinks;
  }, [externalLinkKeysForData, componentsData, patient]);

  function getExternalButtons() {
    return (
      <Grid container spacing={1}>
        {linksData.map((linkData, index) => (
          <Grid item key={index + (linkData?.buttonTitle ?? '') + (linkData?.url ?? '')}>
            <Button
              variant="contained"
              onClick={() => {
                window.open(linkData?.url, '_blank');
              }}
            >
              {linkData?.buttonTitle}
            </Button>
          </Grid>
        ))}
      </Grid>
    );
  }

  return externalLinkKeysForData && externalLinkKeysForData.length > 0
    ? getExternalButtons()
    : null;
}

export default memo(ExternalLink);
