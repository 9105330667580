// components
import { SettingsValueProps } from './components/settings/type';
// routes
import { enUS } from '@mui/material/locale';
import { LanguageResource } from './locales/i18n';
import { PATH_DASHBOARD } from './routes/paths';
import { getBackEndBaseUrl } from './utils/domain-utils';

export enum Modules {
  CCM = 'ccm',
  CRS = 'crs',
  ENGAGEMENT_HUB = 'engagementhub',
}

// API
// ----------------------------------------------------------------------
export const NODE_ENV = process.env.NODE_ENV;
export const REACT_APP_SECTION_CRS = process.env.REACT_APP_SECTION_CRS ?? '';
export const IS_CRS = REACT_APP_SECTION_CRS.toLowerCase() === Modules.CRS;
export const BACKEND_PREFIX_ROUTE = '/api';
export const DEV_BACKEND_URL = process.env.REACT_APP_DEV_BACKEND_URL;

export const REACT_APP_SENTRY_DSN_KEY = process.env.REACT_APP_SENTRY_DSN_KEY;
export const APP_URL = process.env.REACT_APP_APP_URL;
export const DASHBOARD_ROUTE = '/dashboard';
export const DASHBOARD_URL = APP_URL + DASHBOARD_ROUTE;
export const SESSION_EXPIRED_ROUTE = '/session-expired';
export const SESSION_EXPIRED_URL = APP_URL + SESSION_EXPIRED_ROUTE;
export const REDIRECT_AUTHORIZE_ROUTE = '/auth/redirect-authorize-url';
export const REDIRECT_AUTHORIZE_URL = getBackEndBaseUrl() + REDIRECT_AUTHORIZE_ROUTE;
export const RENDER_TEMPLATE_BACK_URL = getBackEndBaseUrl() + '/crs/case/render-template';
export const JASPER_PRINTABLE_CARE_PLAN_FILES_URL = process.env.REACT_APP_JASPER_PRINTABLE_CARE_PLAN_FILES_URL;
console.log('process.env.REACT_APP_JASPER_PRINTABLE_CARE_PLAN_FILES_URL',process.env.REACT_APP_JASPER_PRINTABLE_CARE_PLAN_FILES_URL)
export const JASPER_FUNCTION_URL = getBackEndBaseUrl() + '/crs/case/jasper-report';

export const DEVELOPMENT_CONFIG = {
  disableTokenCheck: process.env.REACT_APP_DEVELOPMENT_DISABLE_TOKEN_CHECK === 'true',
};

export const DEBUG_CONFIG = {
  debugTokenLife: process.env.REACT_APP_DEBUG_TOKEN_LIFE === 'true',
};

export const HAPI_ICD_URL = process.env.REACT_APP_ICD_HAPI_CODES_URL || '';

export const ASSESSMENTS_BASE_URL =
  process.env.REACT_APP_ASSESSMENTS_BASE_URL || 'https://assessments.nicheaim.com/index.php';
export const ASSESSMENT_SOCIAL_HISTORY = process.env.REACT_APP_ASSESSMENT_SOCIAL_HISTORY;
export const ASSESSMENT_PREVENTION_LINK = process.env.REACT_APP_ASSESSMENT_PREVENTION_LINK;

export const FORESIGHT_IFRAME_URL = process.env.REACT_APP_FORESIGHT_IFRAME_URL;
export const PROVIDER_WINDOW_LAUNCH_URL = process.env.REACT_APP_PROVIDER_WINDOW_LAUNCH_URL;
export const FORESIGHT_REPORTS_LAUNCH_URL = process.env.REACT_APP_FORESIGHT_REPORTS_LAUNCH_URL;
export const FORESIGHT_ANALYTICS_LAUNCH_URL = process.env.REACT_APP_FORESIGHT_ANALYTICS_LAUNCH_URL;

export const REACT_APP_CARE_PLAN_PDF_URL = process.env.REACT_APP_CARE_PLAN_PDF_URL;

export const PRINTABLE_CARE_PLAN_TEMPLATE_URL =
  process.env.REACT_APP_PRINTABLE_CARE_PLAN_TEMPLATE_URL;

export const REACT_APP_OUTBOUND_REFERRAL_FEATURE_FLAG = !!eval(
  process.env.REACT_APP_OUTBOUND_REFERRAL_FEATURE_FLAG ?? ''
);

//Yocare

export const YOCARE_FIREBASE_DEFAULT_COLLECTION_PATH =
  process.env.REACT_APP_YOCARE_FIREBASE_DEFAULT_COLLECTION_PATH ?? '';

export const YOCARE_FIREBASE_STORAGE_PROFILE_IMAGE_PATH =
  process.env.REACT_APP_YOCARE_FIREBASE_STORAGE_PROFILE_IMAGE_PATH ?? '';

export const YOCARE_FIREBASE_STORAGE_DATA_SYSTEM_ICON_PATH =
  process.env.REACT_APP_YOCARE_FIREBASE_STORAGE_DATA_SYSTEM_ICON_PATH ?? '';

export const YOCARE_FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_YOCARE_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_YOCARE_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_YOCARE_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_YOCARE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_YOCARE_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_YOCARE_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_YOCARE_FIREBASE_MEASUREMENT_ID,
};

export const TENANT_INFO = {
  logo: process.env.REACT_APP_TENANT_INFO_LOGO,
  tenantName: process.env.REACT_APP_TENANT_INFO_NAME,
  pagePrefix: process.env.REACT_APP_TENANT_INFO_PAGE_PREFIX,
  tenantFavicon: process.env.REACT_APP_TENANT_INFO_FAVICON,
  tenantSpinnerLogo: process.env.REACT_APP_TENANT_INFO_SPINNER_LOGO,
  url: process.env.REACT_APP_TENANT_INFO_URL,
};

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN =
  REACT_APP_SECTION_CRS.toLowerCase() === 'crs'
    ? PATH_DASHBOARD.general.app
    : PATH_DASHBOARD.general.activities; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'default',
  themeStretch: false,
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultLang: LanguageResource = {
  label: 'MCC - Default',
  value: 'mcc',
  icon: '/assets/icons/flags/ic_flag_us.svg',
  themeLocalization: enUS,
};

export const allLangs: LanguageResource[] = [
  defaultLang,
  {
    label: 'NJ',
    value: 'nj',
    icon: '/assets/icons/flags/ic_flag_us.svg',
    themeLocalization: enUS,
  },
  {
    label: 'SBHA',
    value: 'mcc_sbha',
    icon: '/assets/icons/flags/ic_flag_us.svg',
    themeLocalization: enUS,
  },
  {
    label: 'NJ Demo',
    value: 'mcc_demo',
    icon: '/assets/icons/flags/ic_flag_us.svg',
    themeLocalization: enUS,
  }
];

export const FHIR_CATEGORIES = {
  ethnicity: process.env.REACT_APP_ETHNICITY_URL,
  race:
    process.env.REACT_APP_RACE_URL ||
    'http://hl7.org/fhir/us/core/StructureDefinition/us-core-race',
  ssn: process.env.REACT_APP_SYSTEM_SSN || 'http://hl7.org/fhir/sid/us-ssn',
  sexualOrientation:
    process.env.REACT_APP_SEXUAL_ORIENTATION_URL ||
    'http://hl7.org/fhir/us/cdmh/StructureDefinition/cdmh-patient-sexualOrientation',
};

export const config = {
  fhirServerUrl: process.env.REACT_APP_FHIR_API_BASE_URL,
  fhirTenantId: '', // do not set header until FHIR Proxy accepts it again // process.env.REACT_APP_FHIR_TENANT_ID,
  smartLaunchUrl: process.env.REACT_APP_SMART_LAUNCH_URL,
};

export const OBSERVATION_CODE = process.env.REACT_APP_OBSERVATION_CODE;
export const OBSERVATION_TYPE = process.env.REACT_APP_OBSERVATION_TYPE;
export const OBSERVATION_BARRIERS_CODE_SYSTEM =
  process.env.REACT_APP_OBSERVATION_BARRIERS_CODE_SYSTEM || '';
export const OBSERVATION_INCK_SCORES_CODE_SYSTEM =
  process.env.REACT_APP_OBSERVATION_INCK_SCORES_CODE_SYSTEM || '';

export const USE_MINT_REFERRAL_WINDOW = process.env.REACT_APP_USE_MINT_REFERRAL_WINDOW || false;

export const GENDER_IDENTITY_EXTENSION_DEFINITION_URL =
  process.env.REACT_APP_GENDER_IDENTITY_EXTENSION_DEFINITION_URL ?? '';

export const GENDER_IDENTITY_CODE_SYSTEM_URL =
  process.env.REACT_APP_GENDER_IDENTITY_CODE_SYSTEM_URL ?? '';

export const MEDICAID_IDENTIFIER_SYSTEM_URL =
  process.env.REACT_APP_MEDICAID_IDENTIFIER_SYSTEM_URL ?? '';

//Incident Module
// ADD REACT_APP env
export const PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_SYSTEM =
  process.env.REACT_APP_PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_SYSTEM ?? '';

export const PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_TYPE_CODE =
  process.env.REACT_APP_PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_TYPE_CODE ?? '';

export const PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_TYPE_SYSTEM =
  process.env.REACT_APP_PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_TYPE_SYSTEM ?? '';

export const PROVIDER_LICENSE_VERIFICATION_IDENTIFIER_SYSTEM =
  process.env.REACT_APP_PROVIDER_LICENSE_VERIFICATION_IDENTIFIER_SYSTEM ?? '';

export const PROVIDER_LICENSE_VERIFICATION_IDENTIFIER_CODING_SYSTEM =
  process.env.REACT_APP_PROVIDER_LICENSE_VERIFICATION_IDENTIFIER_CODING_SYSTEM ?? '';

export const PROVIDER_LICENSE_IDENTIFIER_CODING_CODE =
  process.env.REACT_APP_PROVIDER_LICENSE_IDENTIFIER_CODING_CODE ?? '';

export const PROVIDER_CLASSIFICATION_CODING_SYSTEM =
  process.env.REACT_APP_PROVIDER_CLASSIFICATION_CODING_SYSTEM ?? '';

export const PROVIDER_CLASSIFICATION_IDENTIFIER_CODING_CODE =
  process.env.REACT_APP_PROVIDER_CLASSIFICATION_IDENTIFIER_CODING_CODE ?? '';

export const PH_META_TAG_CREATED_ON_CODE = process.env.REACT_APP_PH_META_TAG_CREATED_ON_CODE ?? '';

export const PH_META_TAG_CREATED_BY_CODE = process.env.REACT_APP_PH_META_TAG_CREATED_BY_CODE ?? '';

export const PH_META_TAG_CODE_SYSTEM_URL = process.env.REACT_APP_PH_META_TAG_CODE_SYSTEM_URL ?? '';

export const PROVIDER_DIRECTORY_BASE_URL = process.env.REACT_APP_PROVIDER_DIRECTORY_BASE_URL ?? '';

export const PH_META_TAG_PROVIDER_SOURCE_CODE =
  process.env.REACT_APP_PH_META_TAG_PROVIDER_SOURCE_CODE ?? '';

export const PCP_ROLE_CODE = process.env.REACT_APP_PCP_ROLE_CODE ?? '';

export const TASK_INTERNAL_NUMBER_IDENTIFIER_SYSTEM =
  process.env.REACT_APP_TASK_INTERNAL_NUMBER_IDENTIFIER_SYSTEM ?? '';
export const GOAL_INTERNAL_NUMBER_IDENTIFIER_SYSTEM =
  process.env.REACT_APP_GOAL_INTERNAL_NUMBER_IDENTIFIER_SYSTEM ?? '';
export const CAREPLAN_INTERNAL_NUMBER_IDENTIFIER_SYSTEM =
  process.env.REACT_APP_CAREPLAN_INTERNAL_NUMBER_IDENTIFIER_SYSTEM ?? '';
export const SERVICEREQUEST_INTERNAL_NUMBER_IDENTIFIER_SYSTEM =
  process.env.REACT_APP_SERVICEREQUEST_INTERNAL_NUMBER_IDENTIFIER_SYSTEM ?? '';
export const CONSENT_INTERNAL_NUMBER_IDENTIFIER_SYSTEM =
  process.env.REACT_APP_CONSENT_INTERNAL_NUMBER_IDENTIFIER_SYSTEM ?? '';

export const MAX_INACTIVITY_SECONDS =
  (process.env.REACT_APP_MAX_INACTIVITY_SECONDS &&
    parseInt(process.env.REACT_APP_MAX_INACTIVITY_SECONDS)) ||
  900;

export const TRIGGER_REFRESH_TOKEN_FLOW_BEFORE_EXP_SECONDS =
  (process.env.REACT_APP_TRIGGER_REFRESH_TOKEN_FLOW_BEFORE_EXP_SECONDS &&
    parseInt(process.env.REACT_APP_TRIGGER_REFRESH_TOKEN_FLOW_BEFORE_EXP_SECONDS)) ||
  10;

export const EXPIRE_SESSION_DIALOG_TIMEOUT_MS =
  (process.env.REACT_APP_EXPIRE_SESSION_DIALOG_TIMEOUT_MS &&
    parseInt(process.env.REACT_APP_EXPIRE_SESSION_DIALOG_TIMEOUT_MS)) ||
  60000;

export const ALLOWED_ORIGINS_TO_HANDLE_ACTIVITY_IFRAME_MESSAGES =
  (process.env.REACT_APP_ALLOWED_ORIGINS_TO_HANDLE_ACTIVITY_IFRAME_MESSAGES &&
    process.env.REACT_APP_ALLOWED_ORIGINS_TO_HANDLE_ACTIVITY_IFRAME_MESSAGES.split(',')) ||
  [];

export const META_TAGS = process.env.REACT_APP_META_TAGS ?? '';

export const VERBAL_CONSENT = process.env.REACT_APP_VERBAL_CONSENT ?? '';

export const ENGAGEMENT_WORKFLOW = process.env.REACT_APP_ENGAGEMENT_WORKFLOW;

export const TERMINOLOGY_SERVER_BASE_URL = process.env.REACT_APP_TERMINOLOGY_SERVER_BASE_URL ?? '';

export const PROVIDER_CERTIFICATION_IDENTIFIER = {
  type: {
    coding: [
      {
        code: 'providers-directory-certification',
        display: 'Providers Directory Certification',
        system: PROVIDER_DIRECTORY_BASE_URL,
      },
    ],
    text: 'Providers Directory Certification',
  },
};

export const PROVIDER_LICENSE_IDENTIFIER = {
  type: {
    coding: [
      {
        code: 'providers-directory-license',
        display: 'Providers Directory License',
        system: PROVIDER_DIRECTORY_BASE_URL,
      },
    ],
    text: 'Providers Directory License',
  },
};

export const ACTIVITY_DEFINITION_RPM_SETUP_DEVICE = process.env.REACT_APP_ACTIVITY_DEFINITION_RPM_SETUP_DEVICE;
export const ACTIVITY_DEFINITION_RPM_ORDER_DEVICE = process.env.REACT_APP_ACTIVITY_DEFINITION_RPM_ORDER_DEVICE;