import { Assignment as AssignmentIcon, OpenInNew as OpenInNewIcon, AppRegistration as AssignProgramIcon } from '@mui/icons-material';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { TASK_TYPES } from 'src/sections/crs/constants';
import { MenuTaskHandler } from 'src/sections/crs/types';
import { getAssessmentFromTask, isAssessmentReadyToStart } from '../../../../helpers/common';
import Iconify from 'src/components/Iconify';

export const assessmentHandler: MenuTaskHandler = {
  icon: <AssignmentIcon />,
  taskType: TASK_TYPES.ASSESSMENT,
  label: 'Start Assessment',
  conditionToShow: (task) => {
    const inputAssessment = getAssessmentFromTask(task);
    return !!inputAssessment;
  },
  handler: (task, externalHandler) => {
    if (externalHandler) {
      return externalHandler(task);
    }

    const inputAssessment = getAssessmentFromTask(task);
    if (!inputAssessment) return;

    if (isAssessmentReadyToStart(inputAssessment)) {
      window.open(inputAssessment?.valueUrl, '_blank');
    }
  },
};

export const NATHandler: MenuTaskHandler = {
  icon: <OpenInNewIcon />,
  taskType: TASK_TYPES.NAT,
  label: 'Detail',
  conditionToShow: (task) => task?.getTaskType() === TASK_TYPES.NAT,
  handler: (task) => {
    window.open(`${PATH_DASHBOARD.nat.task}/${task?.id}`, '_blank');
  },
};

export const referralHandler: MenuTaskHandler = {
  icon: <Iconify icon="lets-icons:group-share" height={20} width={20}/>,
  taskType: TASK_TYPES.REFERRAL,
  label: 'Service Request',
  conditionToShow:  (task, permission) => {
    const validatePermission = permission ?? true;
    return validatePermission && task?.getTaskType() === TASK_TYPES.REFERRAL;
  },
  handler: (task, externalHandler) => {
    if(externalHandler) 
      return externalHandler(task);
  },
};

export const assignProgramHandler: MenuTaskHandler = {
  icon: <AssignProgramIcon />,
  taskType: TASK_TYPES.PROGRAM,
  label: 'Assign Program',
  conditionToShow:  (task, permission) => {
    const validatePermission = permission ?? true;
    return validatePermission && task?.getTaskType() === TASK_TYPES.PROGRAM;
  },
  handler: (task, externalHandler) => {
    if(externalHandler) 
      return externalHandler(task);
  },
};

export const commonMenuTaskHandlers: MenuTaskHandler[] = [assessmentHandler, NATHandler, referralHandler, assignProgramHandler];
