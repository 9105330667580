import { capitalCase } from 'change-case';
import { format } from 'date-fns';
import { isArray, isString, startCase } from 'lodash';
import moment from 'moment';
import { CodeSystem } from 'src/@nicheaim/fhir-base/mappings/CodeSystem';
import { Immunization } from 'src/@nicheaim/fhir-base/mappings/Immunization';
import { WrappedCommunication } from 'src/@nicheaim/fhir-base/wrappers/Communication';
import { OMBCATEGORY_SYSTEM } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { WrappedServiceRequest } from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import { WrappedValueSet } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { CaseManager, ReferralManager } from 'src/@types/crs/referral';
import { ReferralAndRequestGridRowData } from 'src/@types/nat/request';
import { CaseContactAttemptResponse } from 'src/crs/case/service/CaseService';
import { CaseDto } from 'src/crs/dto/case/case.dto';
import { ReferralDto } from 'src/crs/dto/referral.dto';
import {
  AssessmentsFormsResponse,
  ReferralContactAttemptResponse,
} from 'src/crs/referral/services/ReferralService';
import {
  DocumentReference,
  HumanName,
  ValueSetComposeInclude,
  ValueSetComposeIncludeConcept,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { getDaysSince, getTimeSince, getTimeSince1 } from '../../../utils/formatTime';
import { formattedDateTimeZone } from 'src/utils/dates';

interface ValueSetModified {
  system?: string | undefined;
  code?: string | undefined;
  display?: string | undefined;
}

export function convertValueToValueSetAll(
  value: string,
  valueSet: WrappedValueSet | null
): ValueSetModified | null {
  const include = valueSet?.compose?.include;
  if (!include) return null;

  const modifiedValueSet = {
    system: '',
    code: '',
    display: '',
  };

  include.forEach((i) => {
    const concept = i?.concept;
    if (concept) {
      const match: ValueSetComposeIncludeConcept | null =
        concept.find((c) => c?.code === value) ?? null;
      if (match) {
        const system = i?.system ?? '';
        modifiedValueSet.code = match?.code ?? '';
        modifiedValueSet.display = match?.display ?? '';
        modifiedValueSet.system = system ?? '';
      }
    }
  });

  if (modifiedValueSet.code) {
    return modifiedValueSet;
  }

  return null;
}

export function convertValueToValueSetAllInclude(
  value: string,
  valueSet: WrappedValueSet | null
): ValueSetModified | null {
  if (!valueSet?.compose) return null;

  let includeConcept: ValueSetComposeIncludeConcept | undefined = undefined;
  let include: ValueSetComposeInclude | undefined = undefined;

  for (let i = 0; i < valueSet.compose.include.length; i++) {
    include = valueSet.compose.include[i];

    if (!include.concept) {
      break;
    }

    includeConcept = include.concept.find((c) => c.code === value || c.display === value);

    if (includeConcept) {
      break;
    }
  }

  if (include === undefined || includeConcept === undefined) return null;

  return {
    ...includeConcept,
    system: include?.system,
  };
}

export function convertValueToValueSet(
  value: string,
  valueSet: WrappedValueSet | null
): ValueSetModified | null {
  if (!valueSet?.compose?.include?.[0]?.concept) return null;
  const data = {
    ...valueSet.compose.include[0].concept.find((c) => c.code === value || c.display === value),
    system: valueSet.compose.include[0].system,
  };
  return data ? data : null;
}

export function convertValueToValueSetExpansion(
  value: string,
  valueSet: WrappedValueSet | null
): ValueSetModified | null {
  if (!valueSet?.expansion?.contains) return null;
  const data = {
    ...valueSet.expansion.contains.find((c) => c.code === value || c.display === value),
    system: valueSet.expansion.contains.find((c) => c.code === value || c.display === value)?.system
      ? valueSet.expansion.contains.find((c) => c.code === value || c.display === value)?.system
      : valueSet.expansion.contains?.[0].system,
  };
  return data ? data : null;
}

export function getCodeSystemFromConcept(
  value: string,
  codeSystem: CodeSystem | null
): ValueSetModified | null {
  if (!codeSystem?.concept) return null;
  const data = {
    ...codeSystem.concept.find((c) => c.code === value || c.display === value),
    system: `${process.env.REACT_APP_FHIR_API_BASE_URL}/CodeSystem/${codeSystem?.id}`,
  };
  return data ? data : null;
}

export function getTableRowDueTo(due: Date, status: String) {
  let statusDue: any = {};
  const countDay = getDaysSince(due) * -1;

  if (status === 'completed') {
    statusDue = {
      backgroundColor: '#28a745e6',
      textColor: 'white',
      textShow: '',
      subStatus: 'ontrack',
    };
  } else if (countDay === 2 && status !== 'completed') {
    statusDue = {
      backgroundColor: '#b69329e6',
      textColor: 'white',
      textShow: '(in 2 days)',
      subStatus: 'twodays',
    };
  } else if (due < new Date() && status !== 'completed') {
    statusDue = {
      backgroundColor: '#dc3545e6',
      textColor: 'white',
      textShow: `(${getTimeSince(due)})`,
      subStatus: 'pastdue',
    };
  } else if (due > new Date() && status !== 'completed') {
    statusDue = {
      backgroundColor: '#28a745e6',
      textColor: 'white',
      textShow: `(${getTimeSince1(due)})`,
      subStatus: 'ontrack',
    };
  } else {
    statusDue = { backgroundColor: '', textColor: 'black', textShow: '', subStatus: 'dontdatedue' };
  }

  return statusDue;
}

export function sortByAttribute(arrayToFilter: any[], attribute: string) {
  return arrayToFilter.sort((x1, x2) =>
    x1?.[attribute] > x2?.[attribute] ? 1 : x1?.[attribute] < x2?.[attribute] ? -1 : 0
  );
}

export function simpleSort(a: any, b: any) {
  return a - b;
}

export function localeSort(a: string, b: string) {
  return a.localeCompare(b, 'en', { sensitivity: 'base' });
}

export function mapCommunicationsToNotesDisplay(
  communicationList: WrappedCommunication[],
  code?: string[]
) {
  return communicationList
    .filter(
      (e) => code?.length === 0 || code?.some((r) => r === e?.category?.[0]?.coding?.[0]?.code)
    )
    .map((i) => ({
      topic: i?.topic?.coding?.[0]?.display,
      author: i?.sender?.display,
      type: i?.category?.map((x2) => x2.coding?.[0]?.display)?.join(','),
      description: i?.hasMessages?.() ? i?.getMessages?.()?.join?.(' | ') : i?.getNote?.(),
      date: i?.sent && format(new Date(i?.sent), 'MMM dd, yyyy'),
      medium: i?.medium?.[0]?.coding?.[0]?.display ?? i?.medium?.[0]?.coding?.[0]?.code,
    }));
}

export function mapAssessmentsForms(assessmentsFormList: AssessmentsFormsResponse[]) {
  return assessmentsFormList.map((i) => ({
    generic_id: i?.generic_id,
    survey_id: i?.survey_id,
    response_id: i?.response_id,
    start_language: i?.start_language,
    survey_type: i?.survey_type,
    submit_date: i?.submit_date && format(new Date(i?.submit_date), 'MMM dd, yyyy'),
    previewassessments: i?.survey_id,
  }));
}

export function mapImmunizationsToDisplay(immunizationList: Immunization[]) {
  return immunizationList.map((i) => ({
    name: i?.vaccineCode?.text,
    doses: i?.protocolApplied?.[0]?.seriesDosesPositiveInt,
    status: i?.status,
    occurrence: i?.occurrenceDateTime && format(new Date(i?.occurrenceDateTime), 'MMM dd, yyyy'),
    lotNumber: i?.lotNumber,
    vaccineCode: i?.vaccineCode?.coding?.[0]?.code,
  }));
}

export function mapCaseToCasesDisplay(cas: CaseDto) {
  const row = {
    status: cas?.workflowStatus,
    assignedTo:
      cas?.workflowOwnedBy && startCase(cas?.workflowOwnedBy.split('@')[0].replace('.', ' ')),
    scope: '',
    date: cas?.createdOn && format(new Date(cas?.createdOn), 'MMM dd, yyyy'),
  };
  return row;
}

export function mapContactAttemptsReferral(contactAttempts: ReferralContactAttemptResponse[]) {
  return contactAttempts
    ?.filter((item) => item?.noteId)
    .map((i, index) => ({
      when:
        i?.referralContactAttempContactOn &&
        moment(i?.referralContactAttempContactOn).format('MMM DD, YYYY hh:mm a'),
      outcome: i?.referralContactAttempOutcome,
      type: i?.noteType,
      nextContact: {
        data:
          i?.referralContactAttempNextContactOn &&
          formattedDateTimeZone(i?.referralContactAttempNextContactOn),
        last: index === contactAttempts?.length - 1 ? true : false,
      },
      notes: i?.noteText,
    }));
}

export function mapContactAttemptsCases(contactAttempts: CaseContactAttemptResponse[]) {
  return contactAttempts
    ?.filter((item) => item?.noteId)
    .map((i, index) => ({
      when:
        i?.caseContactAttempContactOn &&
        format(new Date(i?.caseContactAttempContactOn), 'MMM dd, yyyy'),
      outcome: i?.caseContactAttempOutcome,
      type: i?.noteType,
      nextContact: {
        data:
          i?.caseContactAttempNextContactOn &&
          formattedDateTimeZone(i?.caseContactAttempNextContactOn),
        last: index === contactAttempts?.length - 1 ? true : false,
      },
      notes: i?.noteText,
    }));
}

export function mapCommunicationsToConsentFormDisplay(
  communicationList: WrappedCommunication[],
  code?: string
) {
  return communicationList
    .filter((e) => e.category?.[0].coding?.[0].code === code)
    .map((i) => ({
      medium:
        i?.medium?.length === 2
          ? `${i?.medium?.[0].coding?.[0].display} , ${i?.medium?.[1].coding?.[0].display}`
          : i?.medium?.[0].coding?.[0].display,
      author: i?.sender?.display,
      date: i?.sent && format(new Date(i?.sent), 'MMM dd, yyyy'),
      status: i?.status,
    }));
}

export function getIdentifierDocumentReference(
  type: string,
  documentReference: DocumentReference | null
) {
  return documentReference?.identifier?.find(
    (e) => e.type?.text === type || e.system?.includes(type)
  )?.value;
}

export function getLatitudeAndLongitude(data: any) {
  const latitude = data?.extension?.[0]?.extension?.find(
    (a: any) => a.url === 'latitude'
  )?.valueDecimal;
  const longitude = data?.extension?.[0]?.extension?.find(
    (a: any) => a.url === 'longitude'
  )?.valueDecimal;
  const identifier = data?.extension?.[0]?.extension?.find(
    (a: any) => a.url === 'identifier'
  )?.valueString;

  return { latitude, longitude, identifier };
}

export function mapServiceRequesToReferralSummary(service: WrappedServiceRequest | null) {
  const serviceMap = {
    status: service?.status,
    ownedBy: service?.performer?.find((t) => t.type === 'Practitioner')?.reference,
    createdOn: service?.authoredOn,
  };
  return serviceMap;
}

export const caseDtoToCaseManager = (cas: CaseDto): CaseManager => {
  const row = {
    id: cas.fhirId,
    mrn: cas.indexedsubjectMrn,
    patient: cas.indexedsubjectFullName,
    phone: cas.indexedsubjectPhone,
    email: cas.indexedsubjectEmail,
    birthDate: cas.indexedsubjectDob,
    assignmentType: '',
    scope: cas.workflowScope,
    step: cas.workflowStageName,
    date: cas.createdOn,
    status: cas.workflowStatus,
    statusIndexed: cas.indexedStatus,
    internalNumber: cas.internalNumber,
    subjectDOBFormatted: cas.subjectDOBFormatted,
    subjectAge: cas.subjectAge,
    createdOnFormatted: cas.createdOnFormatted,
    owner: cas.workflowOwnedBy,
    stepId: cas.workflowStageId,
    stepOrder: cas.workflowStageOrder,
  };
  return row;
};

export const referralDtoToReferralManager = (ref: ReferralDto): ReferralManager => {
  const row = {
    id: ref.fhirId,
    mrn: ref.indexedsubjectMrn,
    referral: ref.indexedsubjectName,
    phone: ref.indexedsubjectPhone,
    email: ref.indexedsubjectEmail,
    birthDate: ref.indexedsubjectDob,
    gender: ref.indexedSubjectGender,
    race: ref.indexedSubjectRace,
    demographics: ref.indexedSubjectEthnicity,
    registrationDate: ref.indexedRegistrationDate,
    status: ref.workflowStatus,
    statusIndexed: ref.indexedStatus,
    referringProvider: ref.indexedPerformerName,
    requesterOrganization: ref.indexedRequesterName,
    rejected: '',
    workflowStageName: ref.workflowStageName,
    internalNumber: ref.internalNumber,
    subjectDOBFormatted: ref.subjectDOBFormatted,
    subjectAge: ref.subjectAge,
    createdOnFormatted: ref.createdOnFormatted,
    owner: ref.workflowOwnedBy,
    scope: ref.workflowScope,
    stepId: ref.workflowStageId,
    stepOrder: ref.workflowStageOrder,
  };
  return row;
};

export function getFullAddress(address: any) {
  let fullAddress = '';
  if (address) {
    if (isArray(address)) {
      address = address[0];
    }
    const line = address?.line ? address?.line.join(', ') : '';
    fullAddress = `${line}, ${address?.city}, ${address?.state}, ${address?.postalCode}, ${address?.country}`;
  }
  return fullAddress;
}

export function getFullName(name: HumanName[] | null) {
  let fullName: string | null = null;
  if (name?.[0]?.given?.[0] && name?.[0]?.family) {
    fullName = `${name?.[0]?.given?.[0]} ${name?.[0]?.family}`;
  }
  return fullName;
}

export const referralDtoToRequest = (ref: ReferralDto): ReferralAndRequestGridRowData => {
  const row = {
    id: ref?.fhirId || '',
    number: ref?.internalNumber || '',
    created:
      `${moment(ref?.indexedRegistrationDate).format('MMM DD, Y')} ${moment(
        ref.indexedRegistrationDate
      ).format('hh:mm A')}` || '',
    dob: '',
    status: capitalCase(ref?.indexedStatus) || '',
    requester: {
      reference: ref?.indexedRequesterFhirRefUri || '',
      display: ref?.indexedRequesterName || '',
    },
    planDefinition: 'Plan Definition',
    scope: ref?.workflowScope || '',
    step: ref?.workflowStageOrder?.toString() || '',
    category: ref?.workflowScope === 'REFERRAL' ? 'mint_referral' : 'nat_request',
    request: ref,
  };
  return row;
};

export const getWorkflowProperty = (scope: string | null | undefined) => {
  switch (scope) {
    case 'REFERRAL':
      return {
        code: 'REFERRALS_WORKFLOW',
        referenceName: 'REFERRAL',
        steps: 7,
      };
    case 'REMOTE_PATIENT_MONITORING':
      return {
        code: 'REMOTE_PATIENT_MONITORING',
        referenceName: 'REFERRAL',
        steps: 3,
      };
    case 'CASE':
      return {
        code: 'CASES_WORKFLOW',
        referenceName: 'CASE',
        steps: 3,
      };
    case 'ENGAGEMENT':
      return {
        code: 'INITIAL_PATIENT_ENGAGEMENT',
        referenceName: 'ENGAGEMENT',
        steps: 5,
      };
    default:
      return {
        scope: '',
        referenceName: '',
        steps: '0',
      };
  }
};

export const getExtension = (
  coding: any[],
  valueSet: WrappedValueSet | null,
  systemUrl: string,
  url: any
) => {
  const usCoreExtension = coding
    ?.filter(
      (e) =>
        (e.system !== undefined && e.system === OMBCATEGORY_SYSTEM) ||
        (e.code !== undefined && e.code === '')
    )
    ?.map((x) => {
      const coding = convertValueToValueSet(x?.display, valueSet);
      return {
        url: 'ombCategory',
        valueCoding: {
          code: coding?.code,
          display: coding?.display,
          system: 'urn:oid:2.16.840.1.113883.6.238',
        },
      };
    });

  const otherExtension = coding
    ?.filter(
      (e) =>
        (e.system !== undefined && e.system !== OMBCATEGORY_SYSTEM) ||
        (e.code !== undefined && e.code !== '')
    )
    ?.map((x) => {
      const coding = convertValueToValueSetAllInclude(x?.code, valueSet);
      return {
        url: systemUrl,
        valueCodeableConcept: {
          coding: [
            {
              code: coding ? coding?.code : x?.code,
              display: coding ? coding?.display : x?.display,
              system: coding ? coding?.system : x?.system,
            },
          ],
        },
      };
    });

  const extension = [
    ...(usCoreExtension && usCoreExtension.length > 0
      ? [{ extension: [...usCoreExtension], url: url }]
      : []),
    ...(otherExtension && otherExtension.length > 0 ? otherExtension : []),
  ];

  return extension;
};

export const getStatusTextBackgroundColor = (status, theme) => {
  if (status && isString(status)) {
    status = status.toLowerCase();
  }
  if (status && status === 'on-hold') {
    return theme.palette.error.main;
  }
  if (status && status === 'revoked') {
    return theme.palette.error.main;
  }
  return 'none';
};

export const getStatusTextColor = (status, theme) => {
  if (status && isString(status)) {
    status = status.toLowerCase();
  }
  if (status && status === 'on-hold') {
    return theme.palette.common.white;
  }
  if (status && status === 'revoked') {
    return theme.palette.common.white;
  }
  return 'text.secondary';
};