import { WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { ResourceWithIncludedResources } from "../types";
import { ConsentWrapper, WrappedConsent } from "src/@nicheaim/fhir-base/wrappers/Consent";
import { fhirClient } from "src/App";
import { Bundle, Consent, FhirResource, Organization, PractitionerRole } from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";
import { getPractitionerRoleRelatedResources } from "../patient/components/Consent/ConsentGrid/ConsentGrid";
import { getReferenceId } from "src/utils/fhir";
import { mapDataAndDataSchemaWorkflowInstance } from "src/utils/utilities";
import agent from "src/api/agent";

export const getConsents = async (patient: WrappedPatient | null): Promise<ResourceWithIncludedResources<WrappedConsent>[]> => {
  if (!patient?.id) return [];
  try {
    const url = `Consent?patient=${patient.id}&_include=Consent:source-reference&_include=Consent:consentor&_include=Consent:organization&_count=1000`;
    const response = await fhirClient.get<Bundle>(url);
    if (!response?.entry?.length) return [];
    let entries = response.entry.reduce<FhirResource[]>((resources, { resource }) => {
      if (!resource) return resources;
      return [...resources, resource];
    }, []);
    const consents = entries.filter(
      ({ resourceType }) => resourceType === 'Consent'
    ) as Consent[];

    const practitionerRoleRelatedResources = await getPractitionerRoleRelatedResources(consents);

    entries = [...entries, ...practitionerRoleRelatedResources];

    return consents.reduce<ResourceWithIncludedResources<WrappedConsent>[]>(
      (resources, consentUnwrapped) => {
        if (!consentUnwrapped?.id) return resources;
        const consent = ConsentWrapper(consentUnwrapped as Consent);

        const documentReference = entries.find?.(
          ({ id }) => id === getReferenceId(consent?.sourceReference?.reference)
        );

        const organizations =
          consent?.organization?.reduce?.<Organization[]>((organizations, { reference }) => {
            const organization = entries.find?.(
              ({ id }) => id === getReferenceId(reference)
            ) as Organization;
            if (!organization) return organizations;
            return [...organizations, organization];
          }, []) ?? [];

        const performers =
          consent?.performer?.reduce?.<FhirResource[]>((resources, { reference }) => {
            const resource = entries.find?.(({ id }) => id === getReferenceId(reference));
            if (!resource) return resources;
            return [...resources, resource];
          }, []) ?? [];

        const prRelatedResources = performers.reduce<FhirResource[]>((resources, resource) => {
          if (resource?.resourceType !== 'PractitionerRole') return resources;
          const practitionerRole = resource as PractitionerRole;

          const organization = entries.find(
            ({ id }) => id === getReferenceId(practitionerRole?.organization?.reference)
          );

          const practitioner = entries.find(
            ({ id }) => id === getReferenceId(practitionerRole?.practitioner?.reference)
          );
          return [
            ...resources,
            ...(organization ? [organization] : []),
            ...(practitioner ? [practitioner] : []),
          ];
        }, []);

        return [
          ...resources,
          {
            resource: consent,
            includedResources: [
              ...(documentReference ? [documentReference] : []),
              ...organizations,
              ...performers,
              ...prRelatedResources,
            ],
          },
        ];
      },
      []
    );
  } catch (error) {
    return [];
  }
};

export const handlerGenericStepWorkflow = async (
    workflowInstance: any, 
    workflowRecord: any,
    payloadUpdateWorkflowinstance: any,
    checklistData: any,
    userEmail: string,
    modifiedFields: any = null,
    additionalParams: any = null,
) => {
  
  const { data: dataWorkflowInstance, dataSchema } = mapDataAndDataSchemaWorkflowInstance(
    workflowInstance,
    userEmail,
    modifiedFields,
    additionalParams,
    checklistData.code
  );

  const payloadForUpdateWorkflowinstance = {
    ...payloadUpdateWorkflowinstance,
    data: dataWorkflowInstance,
    dataSchema: dataSchema,
  };

  let setCondition = JSON.parse(checklistData.conditions);
  if (setCondition?.data && Array.isArray(setCondition.data)) {
    const manualIndex = setCondition.data.findIndex((e) => e.type === 'Manual');
    if (manualIndex !== -1) {
      setCondition.data[manualIndex].data = {
        confirm: true,
        ...setCondition.data[manualIndex].data,
      };
    }
  }

  const conditions = setCondition ? JSON.stringify(setCondition) : null;

  const payloadForSetItemCompleted = {
    item: { ...checklistData, conditions },
    record: {
      ...workflowRecord,
      ...workflowInstance,
    },
  };

  await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
  await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
}