import { useEffect, useMemo, useState } from 'react';
import {
  useHealthcareServices,
  useOrganizations,
  useServiceRequests,
  useTasks,
} from '../../../../../@nicheaim/fhir-react';
import {
  ServiceRequestWrapper,
  WrappedServiceRequest,
} from '../../../../../@nicheaim/fhir-base/wrappers/ServiceRequest';
import { Box, Card, Grid, SxProps } from '@mui/material';
import { NoteAdd as NoteAddIcon, Edit as EditIcon, FormatListBulleted } from '@mui/icons-material';
import { getIdFromReference } from '../../../../../utils/fhir';
import { OrganizationWrapper } from '../../../../../@nicheaim/fhir-base/wrappers/Organization';
import {
  PatientGenericServiceRequestDto,
  wrappedServiceRequestToPatientGenericServiceRequestDto,
} from './patientGenericServiceRequest.dto';
import { HealthcareServiceWrapper } from '../../../../../@nicheaim/fhir-base/wrappers/HealthcareService';
import AddReferral from 'src/sections/crs/referral/components/child-referral/AddReferral';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import ModalCommunication from 'src/sections/crs/common/ModalCommunication';
import OutboundReferralDetailDrawer from 'src/sections/crs/referral/components/OutboundReferralDetailDrawer';
import CollapsibleDataGrid from 'src/sections/crs/case/components/CollapsibleDataGrid';
import useLocales from 'src/hooks/useLocales';
import { GridColDef } from '@mui/x-data-grid';
import CustomLink from 'src/sections/crs/common/CustomLink';
import CellRow from 'src/sections/crs/common/CellRow';
import moment from 'moment';
import { capitalCase } from 'change-case';
import { PermissionsBase, PermissionsProvider } from 'src/contexts/PermissionsContext';
import CaseTaskGrid from 'src/sections/crs/case/components/CaseTaskGrid';
import { TaskWrapper } from 'src/@nicheaim/fhir-base/wrappers/Task';
import { TaskPermissions } from 'src/sections/crs/types';
import TaskContextProvider from 'src/sections/crs/case/components/TasksByPathway/TaskProvider';
import ActionButton from 'src/components/ActionButton';
import { NestedMenuItem } from 'mui-nested-menu';
import TaskModal from 'src/sections/crs/case/components/TasksGrid/TaskModal';
import { getDisplay, getReferred } from 'src/sections/engagement/intake/components/workflow-step/ReferralInformation';
import { Reference } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';

type Props = {
  patient?: WrappedPatient | null;
  refreshExternal?: boolean;
};

export interface ServiceRequestsPermissions extends PermissionsBase {
  isAllowedToAdd: boolean;
  isAllowedToEdit: boolean;
  isAllowedToViewNotes: boolean;
}

export default function PatientGenericServiceRequest({ patient, refreshExternal }: Props) {
  const [openAdd, setOpenAdd] = useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [serviceRequestToEdit, setServiceRequestToEdit] = useState<WrappedServiceRequest | null>(
    null
  );
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [isReferralDrawerOpen, setIsReferralDrawerOpen] = useState(false);
  const [serviceRequestMap, setServiceRequestMap] = useState<WrappedServiceRequest[] | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [
    serviceRequests,
    { refresh: refreshServiceRequests, isFetching: isServiceRequestLoading },
  ] = useServiceRequests({
    filter: { patient: patient?.id },
    autofetch: !!patient,
    map: ServiceRequestWrapper,
  });

  const serviceRequestIds = useMemo(() => {
    if (!serviceRequests?.length) return [];
    return [
      ...serviceRequests.reduce<Set<string>>((ids, { id }) => {
        if (!id) return ids;
        ids.add(`ServiceRequest/${id}`);
        return ids;
      }, new Set()),
    ];
  }, [serviceRequests]);

  const [tasks, { refresh: refreshTaskList }] = useTasks({
    filter: {
      subject: patient?.id,
      'based-on': serviceRequestIds.join(),
    },
    autofetch: !!serviceRequestIds.length,
    map: TaskWrapper,
  });

  const [organizationIds, setOrganizationIds] = useState<string[]>([]);

  const { i18n } = useLocales();

  const references = useMemo(() => {
    const perf = serviceRequests
      .filter((s) => s?.performer)
      ?.map((s) => s?.performer)
      ?.flat();

    const req = serviceRequests
      .filter((s) => s?.requester)
      ?.map((s) => s?.requester)
      ?.flat();

    const uniqueCombinedRefs = [
      ...new Map([...perf, ...req].map((x) => [x?.reference, x])).values(),
    ];

    return uniqueCombinedRefs;
  }, [serviceRequests]);

  const healthcareServiceIds = useMemo(() => {
    if (references) {
      const organizationRefs = references.filter((x) =>
        x?.reference?.startsWith('HealthcareService')
      );
      if (organizationRefs) {
        return organizationRefs.map((x) =>
          x?.reference ? getIdFromReference(x?.reference) : null
        );
      }
    }

    return [];
  }, [references]);

  const [organizations, { refresh: refreshOrganizations, isFetching: isOrganizationsLoading }] =
    useOrganizations({
      filter: {
        _id: organizationIds?.join(',') ?? null,
      },
      autofetch: !!organizationIds?.join(','),
      map: OrganizationWrapper,
    });

  const [healthcareServices, { isFetching: isHealthCareLoading }] = useHealthcareServices({
    filter: {
      _id: healthcareServiceIds?.join(',') ?? null,
    },
    autofetch: !!healthcareServiceIds?.join(','),
    map: HealthcareServiceWrapper,
  });

  useEffect(() => {
    if (!healthcareServices?.length) return;
    const organizationIds = [
      ...healthcareServices.reduce<Set<string>>((organizationIds, healthCareService) => {
        const organizationId = healthCareService?.providedBy?.reference?.split?.('/')?.[1];
        if (organizationId) organizationIds.add(organizationId);
        return organizationIds;
      }, new Set()),
    ];
    setOrganizationIds(organizationIds);
  }, [healthcareServices]);

  const getReferredFromServiceRequest = async () => {
    const serviceRequestMapped = Promise.all(
      serviceRequests?.map(async (e: WrappedServiceRequest) => {
        const { referred } = await getReferred(e);
        const stringReferred = referred ? getDisplay(referred) : '';
        const referenceReferred: Reference = {
          reference: `${referred?.resourceType}/${referred?.id}`,
          display: stringReferred
        }
        return {
          ...e,
          requester: referenceReferred
        }
    }));
    return serviceRequestMapped;
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const data = await getReferredFromServiceRequest();
      setServiceRequestMap(data);
    };
    fetchData();
    setIsLoading(false);
  }, [serviceRequests]);

  const data = useMemo(() => {
    if (!serviceRequestMap?.length) return [];

    return serviceRequestMap?.map((x) => 
      wrappedServiceRequestToPatientGenericServiceRequestDto(
        x,
        organizations ?? [],
        healthcareServices ?? [],
        tasks ?? []
      )
    );
  }, [serviceRequestMap, organizations, tasks, healthcareServices]);

  const handleClose = () => {
    setOpenAdd(false);
  };

  const permissions: ServiceRequestsPermissions = useMemo(
    () => ({
      isAllowedToAdd: checkAclValidation({ acls: [crsAcls.CRS.PATIENT.SERVICE_REQUESTS.ADD] }),
      isAllowedToEdit: checkAclValidation({ acls: [crsAcls.CRS.PATIENT.SERVICE_REQUESTS.EDIT] }),
      isAllowedToViewNotes: checkAclValidation({
        acls: [crsAcls.CRS.PATIENT.SERVICE_REQUESTS.NOTES.VIEW],
      }),
    }),
    []
  );

  const taskPermissions: TaskPermissions = useMemo(
    () => ({
      isAllowedToAdd: checkAclValidation({ acls: [crsAcls.CRS.PATIENT.SERVICE_REQUESTS.TASKS.ADD] }),
      isAllowedToEdit: checkAclValidation({ acls: [crsAcls.CRS.PATIENT.SERVICE_REQUESTS.TASKS.EDIT] }),
      isAllowedToAssignProgram: checkAclValidation({
        acls: [crsAcls.CRS.PATIENT.SERVICE_REQUESTS.TASKS.ASSIGN_PROGRAM],
      }),
      isAllowedServiceRequest: checkAclValidation({ acls: [crsAcls.CRS.PATIENT.SERVICE_REQUESTS.TASKS.SERVICE_REQUEST] }),
      notes: {
        isAllowedToView: checkAclValidation({
          acls: [crsAcls.CRS.PATIENT.SERVICE_REQUESTS.TASKS.NOTES.VIEW],
        }),
        isAllowedToAdd: checkAclValidation({
          acls: [crsAcls.CRS.PATIENT.SERVICE_REQUESTS.TASKS.NOTES.ADD],
        }),
      },
    }),
    []
  );

  const columns: GridColDef<PatientGenericServiceRequestDto>[] = [
    {
      flex: 1,
      sortable: false,
      field: 'internalNumber',
      headerName: i18n('patients.details.serviceRequests.internalNumber', 'crs'),
      renderCell: (params) => {
        const { row } = params;

        const serviceRequest = serviceRequests?.find?.(({ id }) => id === row.id);

        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <CustomLink
              to="#"
              onClick={() => {
                if (!serviceRequest) return;
                setIsReferralDrawerOpen(true);
                setServiceRequestToEdit(serviceRequest);
              }}
            >
              {row?.internalNumber}
            </CustomLink>
          </Box>
        );
      },
    },
    {
      flex: 0.8,
      sortable: false,
      field: 'created',
      headerName: i18n('patients.details.serviceRequests.created', 'crs'),
      renderCell: (params) => {
        const row = params.row;

        return (
          <CellRow
            shouldTruncateText={false}
            title={
              moment(row?.created ?? null).isValid()
                ? moment(row?.created).format('MM/DD/YYYY')
                : null
            }
          />
        );
      },
    },
    {
      flex: 1,
      sortable: false,
      field: 'referredFromName',
      headerName: i18n('patients.details.serviceRequests.referredFrom', 'crs'),
      renderCell: (params) => {
        const { row } = params;

        return <CellRow shouldTruncateText={false} title={row?.referredFromName} />;
      },
    },
    {
      flex: 1,
      sortable: false,
      field: 'serviceName',
      headerName: i18n('patients.details.serviceRequests.service', 'crs'),
      renderCell: (params) => {
        const { row } = params;

        return <CellRow shouldTruncateText={false} title={row?.serviceName} />;
      },
    },
    {
      flex: 1,
      sortable: false,
      field: 'providedBy',
      headerName: i18n('patients.details.serviceRequests.providedBy', 'crs'),
      renderCell: (params) => {
        const { row } = params;

        return <CellRow shouldTruncateText={false} title={row?.serviceOrganizationName} />;
      },
    },
    {
      flex: 0.8,
      sortable: false,
      field: 'status',
      headerName: i18n('patients.details.serviceRequests.status', 'crs'),
      renderCell: (params) => {
        const { row } = params;

        return (
          <CellRow shouldTruncateText={false} title={row?.status && capitalCase(row?.status)} />
        );
      },
    },
    {
      flex: 0.5,
      sortable: false,
      field: 'action',
      headerName: ' ',
      renderCell: (params) => {
        const { row } = params;
        const serviceRequest = serviceRequests?.find?.(({ id }) => id === row.id);

        return (
          <ActionButton
            renderChildren={(isMenuOpen, setIsMenuOpen) => (
              <>
                <NestedMenuItem
                  sx={nestedMenuItemStyles}
                  onClick={() => {
                    if (!serviceRequest) return;
                    setIsNotesModalOpen(true);
                    setServiceRequestToEdit(serviceRequest);
                  }}
                  leftIcon={<NoteAddIcon />}
                  rightIcon={null}
                  label={i18n('patients.details.serviceRequests.notes', 'crs')}
                  parentMenuOpen={isMenuOpen}
                />
                {!!permissions.isAllowedToEdit && (
                  <NestedMenuItem
                    sx={nestedMenuItemStyles}
                    onClick={() => {
                      if (!serviceRequest) return;
                      setOpenAdd(true);
                      setServiceRequestToEdit(serviceRequest);
                    }}
                    leftIcon={<EditIcon />}
                    rightIcon={null}
                    label={i18n('patients.details.serviceRequests.edit', 'crs')}
                    parentMenuOpen={isMenuOpen}
                  />
                )}
                {taskPermissions.isAllowedToAdd && (
                  <NestedMenuItem
                    sx={nestedMenuItemStyles}
                    onClick={() => {
                      if (!serviceRequest) return;
                      setIsTaskModalOpen(true);
                      setServiceRequestToEdit(serviceRequest);
                    }}
                    leftIcon={<FormatListBulleted />}
                    rightIcon={null}
                    label={i18n('tasks.task', 'crs')}
                    parentMenuOpen={isMenuOpen}
                  />
                )}
              </>
            )}
          />
        );
      },
    },
    {
      flex: 0.1,
      sortable: false,
      field: 'filler',
      headerName: '',
      renderCell: (params) => <CellRow title="" />,
    },
  ];

  useEffect(() => {
    if(refreshExternal) refreshServiceRequests();
  },[refreshExternal])

  return (
    <PermissionsProvider permissions={permissions}>
      <Card
        style={{
          boxShadow: 'none',
          position: 'static',
        }}
        sx={{ m: 1 }}
      >
        <Grid container>
          <Grid item xs={12}>
            <CollapsibleDataGrid
              subGridRowsExtractor={({ tasks }: PatientGenericServiceRequestDto) => tasks ?? []}
              renderCollapsibleContent={({ id, tasks }: PatientGenericServiceRequestDto) => (
                <PermissionsProvider permissions={taskPermissions}>
                  <TaskContextProvider basedOn={[{ reference: `ServiceRequest/${id}` }]}>
                    <CaseTaskGrid
                      patient={patient ?? null}
                      tasks={tasks ?? []}
                      includeSubTasks={false}
                      isNestedGrid={true}
                      showPagination={false}
                      onSuccessfulCreation={refreshTaskList}
                      onSuccessfulEdit={refreshTaskList}
                    />
                  </TaskContextProvider>
                </PermissionsProvider>
              )}
              getRowSpacing={() => ({ bottom: 1, top: 2 })}
              addButtonTitle={i18n('patients.details.serviceRequests.newServiceRequest', 'crs')}
              onAddButtonClick={() => {
                setOpenAdd(true);
                setServiceRequestToEdit(null);
              }}
              density="standard"
              loading={isServiceRequestLoading || isOrganizationsLoading || isHealthCareLoading || isLoading}
              getRowHeight={() => 'auto'}
              onFilterDrawerOpen={() => {}}
              rows={data}
              columns={columns}
              getRowId={({ id }: PatientGenericServiceRequestDto) => id as string}
              showPagination={true}
            />
          </Grid>
        </Grid>
        <AddReferral
          openAdd={openAdd}
          referral={serviceRequestToEdit}
          onReferralSuccess={refreshServiceRequests}
          patient={patient}
          onCancel={handleClose}
        />
        <ModalCommunication
          isAllowedToAdd={checkAclValidation({
            acls: [crsAcls.CRS.PATIENT.SERVICE_REQUESTS.NOTES.ADD],
          })}
          open={isNotesModalOpen}
          patient={patient ?? null}
          onCancel={() => {
            setIsNotesModalOpen(false);
          }}
          resource={serviceRequestToEdit}
          typeNote="notes_referral"
        />
      </Card>
      <OutboundReferralDetailDrawer
        open={isReferralDrawerOpen}
        actionButtonProps={{
          onClick: () => {
            setIsReferralDrawerOpen(false);
            setOpenAdd(true);
          },
        }}
        serviceRequest={serviceRequestToEdit}
        onCloseIconButtonClick={() => {
          setIsReferralDrawerOpen(false);
        }}
      />
      <TaskContextProvider basedOn={[{ reference: `ServiceRequest/${serviceRequestToEdit?.id}` }]}>
        <TaskModal
          onSuccessfulCreation={refreshTaskList}
          onSuccessfulEdit={refreshTaskList}
          patientExternal={patient}
          open={isTaskModalOpen}
          onClose={() => setIsTaskModalOpen(false)}
        />
      </TaskContextProvider>
    </PermissionsProvider>
  );
}

const nestedMenuItemStyles: SxProps = {
  paddingX: 3,
};
