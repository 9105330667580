import { SearchMemberProps } from "../case/components/SearchMember/SearchMember";
import { getOwnerDisplayLabel, TaskSelectedMemberOption, TaskSelectedMemberValue } from "../case/components/TasksGrid/TaskModal";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import useLocales from "src/hooks/useLocales";
import { useCallback, useEffect } from "react";
import useObjectState from "src/hooks/useObjectState";
import { PractitionerRoleWrapper, WrappedPractitionerRole } from "src/@nicheaim/fhir-base/wrappers/PractitionerRole";
import useAuth from "src/hooks/useAuth";
import { getReferenceId } from "src/utils/fhir";
import { Bundle, PractitionerRole } from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";
import { fhirClient } from "src/App";
import { ReferenceResources } from "../types";


export interface SearchMemberModalProps extends SearchMemberProps{
  valueSelected: any;
  options: any;
  labelText: string;
  updateSelectedMember: Function;
  updateState: Function;
  stateKey: string;
  selectedOption: string;
  isRequired?: boolean;
  error?: boolean;
  helperText?: string;
}

const SelectSearchMember = ({
  patient,
  isLoading,
  valueSelected,
  options,
  labelText,
  updateSelectedMember,
  updateState,
  stateKey,
  selectedOption,
  isRequired,
  error,
  helperText
}: SearchMemberModalProps) => {
  const user = useAuth();
  const { i18n } = useLocales();

  const [{ practitionerRoles, isPractitionerRolesLoading }, updatePractitionerRoleState] =
    useObjectState<{
      isPractitionerRolesLoading: boolean;
      practitionerRoles: WrappedPractitionerRole[];
    }>({
      practitionerRoles: [],
      isPractitionerRolesLoading: false,
    });

  const getPractitionerRoles = useCallback(async () => {
    try {
      updatePractitionerRoleState({ isPractitionerRolesLoading: true });
      
      const selectedOrg = user.getSelectedOrg()?.fhir_uri.split('Organization/')?.[1];
      const userPractitionerId = getReferenceId(user?.getCurrentUser()?.user_fhir_uri);
      
      const practitionerRolesResources = await fhirClient.get<Bundle<PractitionerRole>>(
        `PractitionerRole?active=true&practitioner=${userPractitionerId}&organization=${selectedOrg}`
      );

      const practitionerRoles = practitionerRolesResources?.
        entry?.reduce?.<WrappedPractitionerRole[]>((resources, { resource }) => {
          if (!resource) return resources;
          return [...resources, PractitionerRoleWrapper(resource)];
      }, []) ?? [];

      updatePractitionerRoleState({ practitionerRoles });
      
    } catch (error) {}

    updatePractitionerRoleState({ isPractitionerRolesLoading: false });
  }, [user]);

  useEffect(() => {
    getPractitionerRoles();
  }, [getPractitionerRoles]);

  return (
    <Autocomplete
      fullWidth
      disabled={isLoading || isPractitionerRolesLoading}
      value={valueSelected}
      onChange={(_: React.SyntheticEvent, value) => {
        const optionAsValue = value as TaskSelectedMemberValue;
        const optionAsOption = value as TaskSelectedMemberOption;
        if (
          !optionAsValue?.resourceType &&
          optionAsOption?.value !== 'Patient' &&
          optionAsOption?.value === 'Me'
        ) {
          updateSelectedMember({
            isSearchMemberOpen: practitionerRoles?.length === 1 ? false : true,
            [selectedOption]: practitionerRoles?.length === 1 ? 
              practitionerRoles?.[0] : optionAsOption,
            inputSelected: stateKey,
          });
          updateState({ [stateKey]: practitionerRoles?.[0] });
          return;
        }
        if (
          !optionAsValue?.resourceType &&
          optionAsOption?.value !== 'Patient' &&
          optionAsOption
        ) {
          updateSelectedMember({
            isSearchMemberOpen: true,
            [selectedOption]: optionAsOption,
            inputSelected: stateKey,
          });
          return;
        }
        if (optionAsOption?.value === 'Patient') {
          updateSelectedMember({
            isSearchMemberOpen: false,
            [selectedOption]: patient,
            inputSelected: stateKey,
          });
          updateState({ [stateKey]: patient });
          return;
        }
        updateSelectedMember({ [selectedOption]: null, inputSelected: null });
        updateState({ [stateKey]: null });
      }}
      options={options ?? []}
      getOptionLabel={(option) => {
        const optionAsValue = option as TaskSelectedMemberValue;
        const optionAsOption = option as TaskSelectedMemberOption;

        if ((option as unknown as ReferenceResources)?.reference)
          return (option as unknown as ReferenceResources)?.display ?? '';

        if (typeof option === 'string' && option)
          return i18n(
            `fhirResource.${option[0].toLowerCase()}${option.substring(
              1
            )}`,
            'crs'
          );

        if (!optionAsValue?.resourceType) return optionAsOption?.label as string;
        let display = getOwnerDisplayLabel(optionAsValue);
        if (optionAsValue?.resourceType === 'Patient') {
          display = `${display} - Current Patient`;
        }

        return display;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={`${i18n(labelText, 'crs')}${isRequired ? "*" : ""}`}
          error={!!error}
          helperText={helperText}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && !patient ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
};

export default SelectSearchMember;
