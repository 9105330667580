import { SupportAgent } from "@mui/icons-material";
import { Box, Link, Typography } from "@mui/material";
import { useState } from "react";
import { IconButtonAnimate } from "src/components/animate";
import MenuPopover from "src/components/MenuPopover";
import useLocales from "src/hooks/useLocales";
import useTenantConfigData from "src/hooks/useTenantConfigData";

const UserSupport = () => {
  const { i18n } = useLocales();

  const { componentsData } = useTenantConfigData();
  
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      {componentsData?.userSupport?.urlForm && (
        <IconButtonAnimate 
          color={open ? 'primary' : 'default'}
          onClick={handleOpen}
          sx={{ width: 40, height: 40 }}
        >
          <SupportAgent />
        </IconButtonAnimate>
      )}

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 260 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle2">{i18n('userSupportTitle', 'crs')}</Typography>{" "}
            <Link
              href={componentsData?.userSupport?.urlForm}
              color="primary"
              target="_blank"
              rel="noreferrer"
              variant="subtitle2"
            >
              following form
            </Link>
          </Box>
        </Box>
      </MenuPopover>
    </>
  );
};

export default UserSupport;