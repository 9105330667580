import { Card } from '@mui/material';
import { WrappedPatient } from '../../../../../../@nicheaim/fhir-base/wrappers/Patient';
import { checkAclValidation } from '../../../../../../utils/permissions/permission.utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getDeviceRequests,
  getDeviceRequestsByPatient,
  getDeviceRequestsByServiceRequest,
  getDevicesByIds,
} from './api';
import { isArray, isString } from 'lodash';
import useTable from '../../../../../../hooks/useTable';
import CollapsibleDataGrid from '../../../../case/components/CollapsibleDataGrid';
import useLocales from '../../../../../../hooks/useLocales';
import { GridColDef } from '@mui/x-data-grid';
import ObservationDetails from '../observations/ObservationDetails';
import { WrappedServiceRequest } from '../../../../../../@nicheaim/fhir-base/wrappers/ServiceRequest';

type Props = {
  useExternalQuery: boolean;
  patient?: WrappedPatient | null;
  serviceRequest?: WrappedServiceRequest | null;
};

type DeviceRow = {
  id: string;
  requestId: string;
  deviceId?: string;
  deviceName?: string;
  deviceType?: string;
  deviceSerialNumber?: string;
  requestStatus: string;
  patient?: WrappedPatient | null;
};

const getDeviceType = (deviceRequest, device) => {
  let deviceType = '';

  if (device) {
    deviceType = device?.type?.text ? device?.type?.text : device?.type?.coding?.[0]?.display ?? '';
  }
  if (deviceRequest && !deviceType) {
    deviceType = deviceRequest?.codeCodeableConcept?.text
      ? deviceRequest?.codeCodeableConcept?.text
      : deviceRequest?.codeCodeableConcept?.coding?.[0]?.display ?? '';
  }

  return deviceType;
};

const getRowData = (combinedRequestsAndDevices: any[], patient): DeviceRow[] => {
  const formattedRowData: DeviceRow[] = [];
  for (const data of combinedRequestsAndDevices) {
    const row: DeviceRow = {
      id: data?.deviceRequest?.id,
      requestId: data?.deviceRequest?.id,
      requestStatus: data?.deviceRequest?.status ?? '',
      deviceId: data?.device?.id ?? null,
      deviceName: data?.device?.deviceName?.[0]?.name ?? '',
      deviceType: getDeviceType(data?.deviceRequest, data?.device),
      deviceSerialNumber: data?.device?.serialNumber ?? null,
      patient: patient ?? null,
    };
    formattedRowData.push(row);
  }
  // for (const device of devices) {
  //   const row: DeviceRow = {
  //     id: device?.id,
  //     deviceName: device?.deviceName?.[0]?.name ?? '',
  //     status: device?.status ?? '',
  //     patient: patient ?? null,
  //   };
  //   formattedRowData.push(row);
  // }
  // for (const deviceRequest of deviceRequests) {
  //   const row: DeviceRow = {
  //     id: device?.id,
  //     deviceName: device?.deviceName?.[0]?.name ?? '',
  //     status: device?.status ?? '',
  //     patient: patient ?? null,
  //   };
  //   formattedRowData.push(row);
  // }
  return formattedRowData;
};

const getDeviceIdsFromDeviceRequests = (deviceRequests: [], page, rowsPerPage) => {
  // extract Device ids from DeviceRequests
  let deviceIdsToLookup: any[] = deviceRequests
    ?.slice?.(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    ?.filter(
      (x: any) =>
        x?.codeReference?.reference &&
        isString(x?.codeReference?.reference) &&
        x.codeReference.reference.includes('/')
    )
    ?.map((x: any) => x?.codeReference?.reference?.split('/')?.[1]);
  // remove duplicate ids
  deviceIdsToLookup = [...new Set(deviceIdsToLookup)];
  return deviceIdsToLookup;
};

export function DeviceRequestsList({ useExternalQuery = false, patient, serviceRequest }: Props) {
  const [rows, setRows] = useState<DeviceRow[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({});

  const { i18n } = useLocales();

  const handleGetDeviceRequests = useCallback(async () => {
    try {
      setIsLoading(true);
      let result: any = null;
      if (useExternalQuery) {
        if (serviceRequest?.id) {
          result = await getDeviceRequestsByServiceRequest(serviceRequest.id);
        } else if (patient?.id) {
          result = await getDeviceRequestsByPatient(patient.id);
        }
      } else {
        result = await getDeviceRequests();
      }

      // console.log('DEBUG externalQuery result: ', result);
      if (result?.data?.entry && isArray(result?.data?.entry)) {
        const deviceRequestResources = result?.data?.entry?.map((x: any) => x?.resource);
        const deviceIdsToLookup = getDeviceIdsFromDeviceRequests(
          deviceRequestResources,
          page,
          rowsPerPage
        );
        let deviceResources: any[] = [];
        if (deviceIdsToLookup && deviceIdsToLookup.length > 0) {
          const devicesResult = await getDevicesByIds(deviceIdsToLookup);
          if (devicesResult?.data?.entry) {
            deviceResources = devicesResult?.data?.entry?.map((x: any) => x?.resource);
          }
        }

        const combinedRequestsAndDevices: any[] = [];
        for (const deviceRequestResource of deviceRequestResources) {
          const combinedData: any = { deviceRequest: deviceRequestResource };
          // console.log('DEBUG reference: ', deviceRequestResource?.codeReference?.reference);
          const deviceId = deviceRequestResource?.codeReference?.reference?.split('/')?.[1];
          // console.log('DEBUG deviceId: ', deviceId);
          if (deviceId) {
            const deviceIndex = deviceResources.findIndex((x) => x?.id === deviceId);
            const foundDevice = deviceResources?.[deviceIndex];
            if (foundDevice) {
              combinedData.device = foundDevice;
            }
          }
          combinedRequestsAndDevices.push(combinedData);
        }
        console.log('DEBUG combinedRequestsAndDevices: ', combinedRequestsAndDevices);
        // const rowsData = getRowData(deviceResources, deviceRequestResources, patient);
        const rowsData = getRowData(combinedRequestsAndDevices, patient);
        setRows(rowsData);
      } else {
        setRows([]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }, [useExternalQuery, patient, page, rowsPerPage, serviceRequest]);

  useEffect(() => {
    handleGetDeviceRequests();
  }, [handleGetDeviceRequests]);

  // useEffect(() => {
  //   console.log('DEBUG devices:', devices);
  // }, [devices]);

  // useEffect(() => {
  //   console.log('DEBUG rows:', rows);
  // }, [rows]);

  // const paginatedDevices = useMemo(() => devices, [devices]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'deviceType',
        headerName: `${i18n('patients.details.device.deviceType', 'crs')}`,
        flex: 1,
      },
      {
        field: 'deviceSerialNumber',
        headerName: `${i18n('patients.details.device.deviceSerialNumber', 'crs')}`,
        flex: 1,
      },
      {
        field: 'requestStatus',
        headerName: `${i18n('patients.details.device.requestStatus', 'crs')}`,
        flex: 0.3,
      },
    ],
    []
  );

  return (
    <>
      <CollapsibleDataGrid
        addButtonTitle={''}
        showAddButton={false}
        onAddButtonClick={() => {}}
        rows={rows}
        columns={columns}
        renderCollapsibleContent={({ patient, requestId, deviceId }: DeviceRow) => (
          <Card>
            <ObservationDetails
              patient={patient}
              customQuery={{ device: deviceId, patient: patient?.id }}
              showCreate={false}
              showEmpty={!deviceId}
            />
          </Card>
        )}
      />
    </>
  );
}
