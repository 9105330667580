/* eslint-disable */
// @ts-nocheck
import {
  FilterList as FilterListIcon,
  PersonSearch as PersonSearchIcon,
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid, GridSortModel } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { WrappedTask } from 'src/@nicheaim/fhir-base/wrappers/Task';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { useValueSets } from 'src/@nicheaim/fhir-react';
import { TaskResponse } from 'src/@types/crs/task';
import { PaginateQuery } from 'src/api/pagination/dtos';
import FilterDrawer from 'src/components/FilterDrawer';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import PeriodFilter, { onDateChange } from 'src/components/PeriodFilter';
import SearchTextField from 'src/components/SearchTextField';
import { PermissionsProvider } from 'src/contexts/PermissionsContext';
import useGridFilterConsolidation from 'src/hooks/useGridFilterConsolidation';
import useLocales from 'src/hooks/useLocales';
import useObjectState from 'src/hooks/useObjectState';
import useSettings from 'src/hooks/useSettings';
import {
  Reference,
  ValueSetComposeIncludeConcept,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { updateTask } from 'src/services/api/case';
import { getTasks, getTasksAndStats } from 'src/services/api/task';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import { debounce } from 'src/utils/timers';
import SearchMemberModal from '../case/components/SearchMember/SearchMemberModal';
import TaskDetailDrawer from '../case/components/TaskDetailDrawer';
import TaskModal, { getOwnerDisplayLabel } from '../case/components/TasksGrid/TaskModal';
import { TaskPermissions } from '../types';
import getTaskListColumns from './taskColumns';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

export interface GridState {
  page: number;
  rowsPerPage: number;
  orderBy: string | null;
  orderDirection: 'asc' | 'desc' | null;
  search: string;
  statusFilter: ValueSetComposeIncludeConcept[];
  ownerFilter: Reference[];
}

interface TaskDetailDrawerState {
  selectedTaskId: string;
  isTaskDetailDrawerOpen: boolean;
}

interface TaskEditModalState {
  taskToEdit: WrappedTask | null;
  isTaskEditModalOpen: boolean;
  isSearchMemberModalOpen: boolean;
  taskOwnerOptionsByConfig?: any;
}

export interface TaskFilters {
  selectedOwners: Reference[];
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}
export const initialTaskFilters: TaskFilters = {
  selectedOwners: [],
  startDate: null,
  endDate: null,
};

interface TaskListProps {
  includeBreadcrumbs?: boolean;
  list: TaskResponse[];
  setList: Dispatch<SetStateAction<TaskResponse[]>>;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  totalItems: number;
  setTotalItems: Dispatch<SetStateAction<number>>;
  startDate: any;
  setStartDate: Dispatch<any>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  initialGridState: GridState;
  includeStats?: boolean;
  setStats?: Dispatch<SetStateAction<any>>;
  taskProgressStatus?: string;
  show?: boolean;
  ownerFilterUser?: Reference[];
  ownerRequired?: boolean;
}

const TaskList = ({
  includeBreadcrumbs = true,
  list,
  setList,
  searchValue,
  setSearchValue,
  totalItems,
  setTotalItems,
  startDate,
  setStartDate,
  isLoading,
  setIsLoading,
  initialGridState,
  includeStats = false,
  setStats,
  taskProgressStatus,
  show = true,
  ownerFilterUser,
  ownerRequired = false,
}: TaskListProps) => {
  const { themeStretch } = useSettings();
  const [isSearchMemberFilterModalOpen, setIsSearchMemberFilterModalOpen] =
    useState<boolean>(false);
  const [isOpenAplyFilters, setIsOpenAplyFilters] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<any>('');
  const { i18n } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const firstRun = useRef(true);
  const { componentsData } = useTenantConfigData();
  const { searchMemberOptions } = componentsData?.searchMember ?? {};

  const taskPermissions: TaskPermissions = useMemo(
    () => ({
      isAllowedToAdd: checkAclValidation({ acls: [crsAcls.CRS.CASE.TASKS.ADD] }),
    }),
    []
  );

  const [gridState, updateGridState] = useObjectState<GridState>(initialGridState);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: initialGridState.orderBy,
      sort: initialGridState.orderDirection,
    },
  ]);
  const [{ selectedTaskId, isTaskDetailDrawerOpen }, updateDrawerState] =
    useObjectState<TaskDetailDrawerState>({
      selectedTaskId: '',
      isTaskDetailDrawerOpen: false,
    });

  const [{ 
    taskToEdit, 
    isTaskEditModalOpen, 
    isSearchMemberModalOpen, 
    taskOwnerOptionsByConfig 
  }, updateEditModalState] =
    useObjectState<TaskEditModalState>({
      taskToEdit: null,
      isTaskEditModalOpen: false,
      isSearchMemberModalOpen: false,
      taskOwnerOptionsByConfig: []
    });

  useEffect(() => {
    if(!searchMemberOptions?.taskSelectOwner) return;
    updateEditModalState({
      taskOwnerOptionsByConfig: searchMemberOptions?.taskSelectOwner
    })
  }, [searchMemberOptions]);

  const [taskStatusRecords] = useValueSets({
    filter: {
      identifier: 'ph-task-status',
    },
    map: ValueSetWrapper,
  });

  const taskStatus = useMemo(() => taskStatusRecords?.[0] ?? null, [taskStatusRecords]);

  const handleOnTaskEdit = (task: WrappedTask) => {
    updateDrawerState({ isTaskDetailDrawerOpen: false });
    updateEditModalState({
      taskToEdit: task,
      isTaskEditModalOpen: true,
    });
  };

  const handleOnSearchMemberOpen = (task: WrappedTask) => {
    updateEditModalState({
      taskToEdit: task,
      isSearchMemberModalOpen: true,
    });
  };

  const handleOnTaskCopy = (task: WrappedTask) => {
    delete task?.id;
    updateEditModalState({ taskToEdit: task, isTaskEditModalOpen: true });
  };

  const handleAddNoteToTask = (task: WrappedTask) => {
    updateEditModalState({ taskToEdit: task, addNoteToTask: true });
  };

  const handleAssignProgram = (task: WrappedTask) => {
    updateEditModalState({ taskToEdit: task, isAssignProgramModalOpen: true });
  };

  const handleSearchChange = useMemo(
    () =>
      debounce((search: string) => {
        updateGridState({ search });
      }, 600),
    []
  );

  const handleTaskDetailsOpen = useCallback((fhirId: string) => {
    updateDrawerState({
      isTaskDetailDrawerOpen: true,
      selectedTaskId: fhirId,
    });
  }, []);

  const fetchTaskList = async () => {
    setIsLoading(true);
    const { page, rowsPerPage, orderBy, search, orderDirection, statusFilter, ownerFilter } =
      gridState;
    const paginateQuery: PaginateQuery = {
      page: page,
      limit: rowsPerPage,
      sortBy: orderBy && orderDirection ? [[orderBy, orderDirection.toUpperCase()]] : [],
    };

    if (searchValue) {
      paginateQuery.search = searchValue;
    }

    paginateQuery.filter = {};

    if (statusFilter.length) {
      paginateQuery.filter.status = `$in:${statusFilter.map(({ code }) => code).join(',')}`;
    }
    if (startDate) {
      paginateQuery.filter.periodStart = includeStats
        ? `$gte:${startDate._d.toISOString()}`
        : startDate._d.toISOString();
    }

    if (endDate) {
      paginateQuery.filter.periodEnd = includeStats
        ? `$lte:${endDate._d.toISOString()}`
        : endDate._d.toISOString();
    }
    if (ownerFilter.length) {
      paginateQuery.filter.ownerFhirId = `$in:${ownerFilter
        .map(({ reference }) => reference)
        .join(',')}`;
    }

    if (ownerRequired && ownerFilter.length === 0) {
      if (!firstRun.current) {
        enqueueSnackbar('Task owner is required, try pressing clear all button to reset filters', {
          variant: 'error',
        });
      } else {
        firstRun.current = false;
      }

      setIsLoading(false);
      return;
    }

    if (taskProgressStatus) {
      paginateQuery.filter.taskProgressStatus = taskProgressStatus;
    }

    const response = includeStats
      ? await getTasksAndStats(paginateQuery)
      : await getTasks(paginateQuery);
    const data = response?.data;

    setList(data ?? []);
    if (response?.meta?.totalItems !== undefined) setTotalItems(response?.meta?.totalItems);
    if (includeStats && setStats && response?.stats) {
      setStats(response?.stats);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTaskList();
  }, [searchValue, gridState, taskProgressStatus]);

  function backToInitialState() {
    if (ownerFilterUser && ownerFilterUser?.length > 0) {
      updateGridState({
        ...initialGridState,
        ownerFilter: ownerFilterUser,
      });
    } else {
      updateGridState(initialGridState);
    }
  }

  useEffect(() => {
    if (ownerFilterUser && ownerFilterUser?.length > 0)
      updateGridState({
        ...gridState,
        ownerFilter: ownerFilterUser,
      });
  }, [ownerFilterUser]);

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    updateGridState({ rowsPerPage });
  };

  const handlePageChange = (page: number) => {
    updateGridState({ page: page + 1 });
  };

  const handleSortModelChangeCallback = useCallback(
    (newSortModel: GridSortModel) => {
      setSortModel(newSortModel);

      const { field, sort } = newSortModel?.[0] ?? {};
      updateGridState({
        orderBy: field ?? null,
        orderDirection: sort ?? null,
      });
    },
    [updateGridState]
  );

  const handleSortChangeCallback = useCallback(
    (field: string, order: 'asc' | 'desc') => {
      updateGridState({
        orderBy: field ?? null,
        orderDirection: order ?? null,
      });
    },
    [updateGridState]
  );

  const { filters, updateFilters, onClearAllFilters } = useGridFilterConsolidation<any>(
    initialTaskFilters,
    initialTaskFilters
  );
  const handleOnSuccesEdit = (_: any) => {
    fetchTaskList();
  };

  const handleOwnerChange: onSelectResource = async (resource) => {
    updateEditModalState({ isSearchMemberModalOpen: false });
    const response = await updateTask(
      {
        ownerId: `${resource?.resourceType}/${resource?.id}`,
        ownerDisplay: getOwnerDisplayLabel(resource),
      },
      taskToEdit?.id as string
    );
    if (!response) {
      enqueueSnackbar('Unable to update Task Owner. Please Try Again', {
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar('Task Owner Succesfully Updated');
    handleOnSuccesEdit();
  };

  if (!show) {
    return null;
  }

  return (
    <Box sx={{ width: '100% !important' }}>
      <Page title={i18n('tasks.title', 'crs')}>
        <Container maxWidth={themeStretch ? false : 'xl'}>
          {includeBreadcrumbs ? (
            <HeaderBreadcrumbs
              title={i18n('tasks.title', 'crs')}
              heading=""
              links={[
                { name: `${i18n('admin.list.dashboard')}`, href: PATH_DASHBOARD.root },
                { name: `${i18n('breadcrumbs.title', 'crs')}` },
                { name: `${i18n('tasks.title', 'crs')}`, href: PATH_DASHBOARD.crs.task },
              ]}
            />
          ) : null}

          <Grid item xs={12} sx={{ marginTop: 4 }}>
            <Card sx={{ width: '100%', paddingTop: 2, paddingX: 1 }}>
              <Grid
                container
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 2,
                  paddingX: 1,
                }}
                spacing={2}
              >
                <Grid item xs={3}>
                  <SearchTextField
                    value={searchValue}
                    onChange={(event) => {
                      const value = event.target.value;
                      setSearchValue(value);
                      handleSearchChange(value);
                    }}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6}>
                  <Button
                    sx={{ mt: 1, marginLeft: 'auto' }}
                    variant="contained"
                    onClick={() => {
                      fetchTaskList();
                    }}
                  >
                    {i18n('dashboard.refresh', 'crs')}
                  </Button>
                </Grid>

                <Grid container alignItems="center" justifyContent="flex-end" xs={3}>
                  <Tooltip title="Filter">
                    <IconButton
                      onClick={() => {
                        setIsOpenAplyFilters(true);
                      }}
                      sx={{ height: '40px' }}
                    >
                      <FilterListIcon htmlColor="#919eab" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <DataGrid
                sx={{
                  width: '100% !important',
                  '& .MuiDataGrid-columnHeader': { backgroundColor: '#f4f6f8' },
                  '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                  },
                }}
                rows={list}
                loading={isLoading}
                autoHeight={true}
                getCellClassName={() => 'taskGridBodyCell'}
                getRowId={({ fhirId }: TaskResponse) => fhirId}
                getRowSpacing={() => ({ bottom: 5 })}
                getRowHeight={() => 130}
                columns={getTaskListColumns({
                  taskStatuses: taskStatus?.asList?.() ?? [],
                  i18n,
                  onTaskOpen: handleTaskDetailsOpen,
                  onEditTask: handleOnTaskEdit,
                  onSearchMemberOpen: handleOnSearchMemberOpen,
                  onCopyTask: handleOnTaskCopy,
                  onAddNoteToTask: handleAddNoteToTask,
                  onSuccessfulEdit: handleOnSuccesEdit,
                  isLoading,
                  handleSortChange: handleSortChangeCallback,
                })}
                isRowSelectable={() => false}
                disableColumnFilter
                disableColumnMenu
                rowCount={totalItems}
                pagination
                paginationMode="server"
                sortingMode="server"
                sortModel={sortModel}
                onSortModelChange={handleSortModelChangeCallback}
                pageSize={gridState.rowsPerPage}
                onPageSizeChange={handleChangeRowsPerPage}
                onPageChange={handlePageChange}
                rowsPerPageOptions={[5, 10, 25]}
                page={gridState.page - 1}
              />
            </Card>
          </Grid>
        </Container>
      </Page>

      <SearchMemberModal
        open={isSearchMemberModalOpen || isSearchMemberFilterModalOpen}
        taskOwnerOptionsByConfig={searchMemberOptions?.taskSelectOwner ?? []}
        onCancel={() => {
          updateEditModalState({
            isSearchMemberModalOpen: false,
          });
          setIsSearchMemberFilterModalOpen(false);
        }}
        onSelectResource={(resource) => {
          if (isSearchMemberFilterModalOpen) {
            updateGridState(({ ownerFilter }) => ({
              ownerFilter: [
                ...(ownerFilter ?? []),
                { display: getOwnerDisplayLabel(resource), reference: resource?.id },
              ],
            }));
            setIsSearchMemberFilterModalOpen(false);
          } else {
            handleOwnerChange(resource);
          }
        }}
      />
      <TaskDetailDrawer
        onEdit={(task) => {
          updateDrawerState({ isTaskDetailDrawerOpen: false });
          updateEditModalState({
            taskToEdit: task,
            isTaskEditModalOpen: true,
          });
        }}
        open={isTaskDetailDrawerOpen}
        taskId={selectedTaskId}
        onSuccesfulEdit={() => {
          fetchTaskList();
        }}
        onCloseIconButtonClick={() => {
          updateDrawerState({ isTaskDetailDrawerOpen: false });
        }}
        isAllowedToEdit={true}
      />
      <PermissionsProvider permissions={taskPermissions}>
        <TaskModal
          taskToEdit={taskToEdit as WrappedTask}
          onSuccessfulEdit={handleOnSuccesEdit}
          onSuccessfulCreation={handleOnSuccesEdit}
          open={isTaskEditModalOpen}
          onClose={() => {
            updateEditModalState({
              isTaskEditModalOpen: false,
            });
          }}
        />
      </PermissionsProvider>
      <FilterDrawer
        onApplyButtonClick={() => {
          backToInitialState();
          fetchTaskList();
          setIsOpenAplyFilters(false);
        }}
        title={i18n('patients.details.tasks.filterPopUp.title', 'crs')}
        onCloseIconButtonClick={() => {
          setIsOpenAplyFilters(false);
        }}
        onClearAllButtonClick={() => {
          setStartDate(null);
          setEndDate(null);
          backToInitialState();
        }}
        anchor={'right'}
        open={isOpenAplyFilters}
        i18n={i18n}
      >
        <TaskFilterList
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={(value) => {
            console.debug('TaskList value', value?.toISOString());
            const endOfDay = value?.endOf('day');
            console.debug('TaskList endOfDay', endOfDay?.toISOString());

            setEndDate(endOfDay);
          }}
          gridState={gridState}
          updateGridState={updateGridState}
          taskStatus={taskStatus}
          setIsSearchMemberModalOpen={setIsSearchMemberFilterModalOpen}
          i18n={i18n}
        />
      </FilterDrawer>
    </Box>
  );
};
export type OnOwnerSelectionChange = (event: React.SyntheticEvent, newValue: Reference[]) => void;

interface TaskFilterListProps {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  onStartDateChange: onDateChange;
  onEndDateChange: onDateChange;
  gridState: any;
  updateGridState: any;
  taskStatus: any;
  setIsSearchMemberModalOpen: any;
  i18n: any;
}

const TaskFilterList = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  gridState,
  updateGridState,
  taskStatus,
  setIsSearchMemberModalOpen,
  i18n,
}: TaskFilterListProps) => (
  <Box py={3}>
    <Box>
      <Grid item sx={{ marginTop: 1 }} xs={5}>
        <Typography fontSize={'1rem'} fontWeight={'bold'} marginBottom={1.4}>
          Status
        </Typography>
        <Autocomplete
          value={gridState.statusFilter}
          multiple
          fullWidth
          onChange={(_: React.SyntheticEvent, taskStatus) => {
            console.log(taskStatus);
            updateGridState((prevGridState: any) => ({
              ...prevGridState,
              statusFilter: taskStatus,
            }));
          }}
          options={taskStatus?.asList() ?? []}
          getOptionLabel={({ display }: ValueSetComposeIncludeConcept) => display ?? ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label={i18n('patients.details.tasks.filterPopUp.status', 'crs')}
              variant="outlined"
            />
          )}
        />
      </Grid>
    </Box>
    <Box marginTop={3} marginBottom={1}>
      <PeriodFilter
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={onStartDateChange}
        onEndDateChange={onEndDateChange}
      />
    </Box>

    <Box marginTop={3} marginBottom={1}>
      <Grid item xs={3}>
        <Autocomplete
          open={false}
          value={gridState.ownerFilter}
          multiple
          fullWidth
          sx={{
            '& .MuiAutocomplete-popupIndicator': {
              visibility: 'hidden',
            },
          }}
          onChange={(_: React.SyntheticEvent, owners) => {
            updateGridState((prevGridState: any) => ({
              ...prevGridState,
              ownerFilter: owners,
            }));
          }}
          options={gridState.ownerFilter}
          getOptionLabel={({ display }: Reference) => display ?? ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label={i18n('patients.details.tasks.filterPopUp.owner', 'crs')}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <Tooltip title="Click here to search by owner">
                      <IconButton
                        onClick={() => {
                          setIsSearchMemberModalOpen(true);
                        }}
                      >
                        <PersonSearchIcon />
                      </IconButton>
                    </Tooltip>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
    </Box>
  </Box>
);
export default TaskList;
