import axiosFhirInstance from '../../../../../../application/adapters/out/repositories/axiosFhirInstance';

export const getDevices = async () => {
  const query = `Device`;
  const result = await axiosFhirInstance.get(query);
  return result;
};

export const getDevicesByIds = async (ids: string[]) => {
  const query = `Device?_id=${ids.join(',')}`;
  const result = await axiosFhirInstance.get(query);
  return result;
};

export const getDeviceRequestsByPatient = async (patientId: string) => {
  const query = `DeviceRequest?patient=${patientId}`;
  const result = await axiosFhirInstance.get(query);
  return result;
};

export const getDeviceRequestsByServiceRequest = async (patientId: string) => {
  const query = `DeviceRequest?based-on=${patientId}`;
  const result = await axiosFhirInstance.get(query);
  return result;
};

export const getDeviceRequests = async () => {
  const query = `DeviceRequest`;
  const result = await axiosFhirInstance.get(query);
  return result;
};
