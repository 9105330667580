import { Box, Typography, Stack, Link, useTheme } from '@mui/material';
import moment from 'moment';
import { getSeverityDueDateData } from 'src/sections/crs/helpers/getSeverityDueDateData';
import { ReferralDto } from 'src/crs/dto/referral.dto';
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import AttachmentsTable from './AttachmentsTable';
import { NOTAPPLICABLE } from 'src/sections/crs/constants';
import { getStatusTextBackgroundColor, getStatusTextColor } from '../../../../common/common-utils';

interface ReferralTabPanelProps {
  index: number;
  selectedTab: number;
  referral: ReferralDto;
  patientId: string;
}

const ReferralTabPanel = ({ referral, selectedTab, index, patientId }: ReferralTabPanelProps) => {
  const { formattedDueDate: createdOn } = getSeverityDueDateData(
    moment(referral.createdOn ?? null)
  );
  const dispositionOnMoment = moment(referral.workflowDispositionOn ?? null);
  const { formattedDueDate: dispositionOn } = getSeverityDueDateData(dispositionOnMoment);

  const theme = useTheme();

  return (
    <div
      role="tabpanel"
      hidden={selectedTab !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {selectedTab === index && (
        <Box marginTop={2.6}>
          <InfoSection
            label="Referral No"
            value={
              referral.fhirId && referral.internalNumber ? (
                <Link
                  href={`/dashboard/referral/${referral.fhirId}`}
                  target="_blank"
                  underline="always"
                >
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        marginRight: 0.7,
                      }}
                    >
                      {referral.internalNumber}
                    </Typography>

                    <OpenInNewIcon sx={{ bottom: 20 }} fontSize="small" />
                  </Box>
                </Link>
              ) : (
                ''
              )
            }
          />

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            marginBottom={1}
          >
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              Status
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: getStatusTextColor(referral?.indexedStatus, theme),
                textTransform: 'capitalize',
                backgroundColor: () => getStatusTextBackgroundColor(referral?.indexedStatus, theme),
              }}
            >
              {referral?.indexedStatus}
            </Typography>
          </Stack>

          <InfoSection label="Owner" value={referral.workflowOwnedBy} />
          <InfoSection
            label="Created On"
            value={createdOn !== NOTAPPLICABLE ? createdOn : dispositionOn}
          />
          <InfoSection capitalize label="Scope" value={referral.workflowScope} />
          <InfoSection
            label="Disposition"
            value={dispositionOn !== NOTAPPLICABLE ? dispositionOn : ''}
          />

          <AttachmentsTable referralId={referral.fhirId} patientId={patientId} />
        </Box>
      )}
    </div>
  );
};

export interface InfoSectionProps {
  label: string;
  value: React.ReactNode;
  capitalize?: boolean;
}

const InfoSection = ({ label, value, capitalize = false }: InfoSectionProps) => (
  <Stack direction="row" alignItems="center" justifyContent="space-between" marginBottom={1}>
    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
      {label}:
    </Typography>
    {typeof value === 'string' ? (
      <Typography
        variant="body2"
        sx={{ color: 'text.secondary', textTransform: capitalize ? 'capitalize' : 'none' }}
      >
        {value ?? ''}
      </Typography>
    ) : (
      <>{value}</>
    )}
  </Stack>
);

export default ReferralTabPanel;
