import { CheckCircle, Pending, PlayCircleFilledRounded } from '@mui/icons-material';
import {
  Backdrop,
  CircularProgress,
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { lowerCase } from 'lodash';
import { useEffect } from 'react';
import { WrappedConsent } from 'src/@nicheaim/fhir-base/wrappers/Consent';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import CustomModal, {
  CustomModalBasicProps,
  GridItem,
  GridSection,
} from 'src/components/CustomModal';
import useLocales from 'src/hooks/useLocales';
import useObjectState from 'src/hooks/useObjectState';
import useTenantConfigData from 'src/hooks/useTenantConfigData';
import { Period } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { StatusChip } from 'src/sections/careflow/common';
import CancelButton from 'src/sections/crs/common/CancelButton';
import ConsentForm from 'src/sections/crs/patient/components/Consent/ConsentForm';
import { ResourceWithIncludedResources } from 'src/sections/crs/types';
import { LabelColor } from 'src/sections/engagement/intake/components/workflow-step/ProviderInformation';
import { getFormattedDatePeriod } from 'src/utils/dates';
import uuidv4 from 'src/utils/uuidv4';

export type SetDataExternal = {
  consentType: ConsentOption | undefined;
  status: string | undefined;
  scope: string | undefined;
  patientReference: string | undefined;
  patientName: string | undefined;
};

export interface ConsentOption {
  code: string;
  label: string;
  required: boolean;
}

interface selectedOptionCustomizedButton {
  title: string | null;
  sub: string;
  type: string;
  icon: JSX.Element;
  label: string;
  labelColor: LabelColor;
}

interface ConsentSelectedMemberState {
  isSearchMemberOpen: boolean;
  isSearchAttachOpen: boolean;
  selectedOptions: ConsentOption[] | null;
  selectedConsentType: any | null;
  selectedOptionCustomizedButton: selectedOptionCustomizedButton[];
  setData: SetDataExternal | null;
}

interface Props extends CustomModalBasicProps {
  patient: WrappedPatient | null;
  open: boolean;
  isLoading: boolean;
  consentOption: ConsentOption[] | null;
  consents: ResourceWithIncludedResources<WrappedConsent>[];
  breadcrumbs: string[];
  handlerSave: () => Promise<any>;
}

export default function ConsentInformation({
  patient,
  consentOption,
  consents,
  open,
  isLoading,
  breadcrumbs,
  onClose,
  handlerSave,
}: Props) {
  const { i18n } = useLocales();
  const { componentsData } = useTenantConfigData();

  const title = [
    ...breadcrumbs,
    `${i18n(
      'patientEngagement.details.workflow.checklist.consentInformation.title',
      'engagement'
    )}`,
  ];

  const [
    {
      isSearchMemberOpen,
      isSearchAttachOpen,
      selectedOptions,
      selectedConsentType,
      selectedOptionCustomizedButton,
      setData,
    },
    updateSelectedMember,
  ] = useObjectState<ConsentSelectedMemberState>({
    isSearchMemberOpen: false,
    isSearchAttachOpen: false,
    selectedOptions: null,
    selectedConsentType: null,
    selectedOptionCustomizedButton: [],
    setData: null,
  });

  const [{ consentData }, updateState] = useObjectState<{
    consentData: ResourceWithIncludedResources<WrappedConsent> | null;
  }>({
    consentData: null,
  });

  useEffect(() => {
    updateSelectedMember({
      isSearchMemberOpen,
      selectedOptions: consentOption,
      selectedConsentType: null,
      selectedOptionCustomizedButton: [
        ...getSelectOptionCustomButton(consents, consentOption ?? []),
      ],
    });
  }, [open, consentOption, consents]);

  useEffect(() => {
    const consent =
      consents?.find((e) => e?.resource?.getFirstCategory()?.code === selectedConsentType?.code) ??
      null;
    updateState({ consentData: consent });

    if (consent) {
      updateSelectedMember({
        selectedOptionCustomizedButton: [
          ...getSelectOptionCustomButton(consents, consentOption ?? []),
        ],
      });
      return;
    }

    updateSelectedMember((prevState) => {
      const isConsentTypeDifferent =
        prevState.setData?.consentType !== undefined &&
        prevState.setData?.consentType !== selectedConsentType?.code;

      let customizedButton = selectedOptionCustomizedButton;

      if (isConsentTypeDifferent) {
        customizedButton = selectedOptionCustomizedButton?.map((r) => {
          if (r?.type === prevState.setData?.consentType?.code) {
            return getCustomButton('', '', '', prevState.setData.consentType);
          }
          return r;
        });
      }

      const consentStatus = componentsData.consent?.defaultValues?.status || 'inactive';
      const consentScope = componentsData.consent?.defaultValues?.scope || 'research';

      return {
        selectedOptionCustomizedButton: customizedButton,
        setData: {
          consentType: selectedConsentType,
          status: consentStatus,
          scope: consentScope,
          patientReference: `${patient?.resourceType}/${patient?.id}`,
          patientName: `${patient?.getFullName()}`,
        },
      };
    });
  }, [selectedConsentType, consents, componentsData]);

  useEffect(() => {
    updateSelectedMember({
      selectedOptionCustomizedButton: [
        ...getSelectOptionCustomButton(consents, consentOption ?? []),
      ],
    });
  }, [isSearchMemberOpen, consents, consentOption]);

  const handleClose = () => {
    if (isSearchMemberOpen) {
      updateSelectedMember({ isSearchMemberOpen: false, isSearchAttachOpen: false });
    } else {
      onClose?.({}, 'backdropClick');
    }
  };

  return (
    <CustomModal
      keepMounted
      open={open}
      title={title[2]}
      breadcrumbs={title}
      onCancel={handleClose as Function}
      onClose={handleClose}
      showCancelButton={false}
      showSaveButton={false}
      isLoading={isLoading}
      containerSx={[{ overflow: 'scroll', pb: 0 }, isSearchMemberOpen ? { width: '94vw' } : {}]}
    >
      <>
        <Backdrop
          sx={{ background: 'transparent', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container my={3} justifyContent={'space-between'}>
          <GridSection mt={0}>
            <Typography variant="caption" sx={{ color: 'gray' }}>
              {`${i18n('patientEngagement.details.workflow.checklist.consentInformation.comment', 'engagement')}`}
            </Typography>
          </GridSection>

          <Grid item xs={isSearchMemberOpen ? (isSearchAttachOpen ? 1 : 5) : 12}>
            <GridSection>
              <GridItem xs={12} mt={2}>
                <Stack display="flex" spacing={2}>
                  {selectedOptions &&
                    selectedOptions.map((option: ConsentOption) => (
                      <CustomizedButton
                        key={uuidv4()}
                        customizedButton={selectedOptionCustomizedButton}
                        consentOption={option}
                        isSearchAttachOpen={isSearchAttachOpen}
                        typeSelected={selectedConsentType?.code ?? ''}
                        handler={() => {
                          const optionAsOption = option as ConsentOption;
                          if (optionAsOption) {
                            updateSelectedMember({
                              isSearchMemberOpen: true,
                              selectedConsentType: optionAsOption,
                            });
                            return;
                          }

                          updateSelectedMember({ selectedConsentType: null });
                        }}
                      />
                    ))}
                </Stack>
              </GridItem>
            </GridSection>
          </Grid>

          {isSearchMemberOpen && (
            <Grid item xs={isSearchAttachOpen ? 10.9 : 6.9}>
              <ConsentForm
                patient={patient}
                consentData={consentData}
                onSaveChanges={handlerSave}
                open={selectedConsentType}
                onClose={handleClose}
                setDataExternal={setData}
                disabled={true}
                onSelectProperties={(
                  isSearchAttachOpen,
                  consentStatus,
                  provisionPeriodStart,
                  provisionPeriodEnd
                ) => {
                  const customizedButton = selectedOptionCustomizedButton?.map((r) => {
                    if (r?.type === selectedConsentType?.code) {
                      return getCustomButton(
                        provisionPeriodStart ?? '',
                        provisionPeriodEnd ?? '',
                        consentStatus ?? '',
                        selectedConsentType
                      );
                    }
                    return r;
                  });

                  updateSelectedMember({
                    isSearchAttachOpen,
                    // selectedOptionCustomizedButton: customizedButton,
                  });
                }}
              />
            </Grid>
          )}

          {!isSearchMemberOpen && (
            <Grid item xs={12} sx={{ mt: '32px', display: 'flex', justifyContent: 'flex-end' }}>
              <CancelButton
                onClick={() => {
                  onClose?.({}, 'backdropClick');
                }}
              />
            </Grid>
          )}
        </Grid>
      </>
    </CustomModal>
  );
}

interface CustomizedButtonProps {
  customizedButton: selectedOptionCustomizedButton[];
  consentOption: ConsentOption;
  typeSelected: string;
  isSearchAttachOpen: boolean;
  handler: (option: any) => any;
}

function CustomizedButton({
  customizedButton,
  consentOption,
  typeSelected,
  isSearchAttachOpen,
  handler,
}: CustomizedButtonProps) {
  const getCustomButtonProperties = customizedButton.filter((e) => e.type === consentOption.code);

  const { icon, title, sub, type: typeCode, label, labelColor } = getCustomButtonProperties[0];

  const isSelected = consentOption.code === typeSelected;
  const isRequired = consentOption.required ? "*" : "";

  return (
    <ListItemButton
      sx={{
        pl: 0,
        ...(isSearchAttachOpen && { flexDirection: 'column', pr: 0 }),
        typography: 'body2',
        fontWeight: 'bold',
        color: 'text.secondary',
        border: `1px dashed #e0e0e0`,
        borderRadius: 1,
        bgcolor: 'transparent',
        ...(isSelected && { boxShadow: 12 }),
        '&:hover': {
          backgroundColor: 'transparent',
          boxShadow: 7,
        },
      }}
      disableTouchRipple
      alignItems="center"
      onClick={handler}
    >
      <ListItemIcon sx={{ mx: 1 }}>{icon}</ListItemIcon>
      <ListItemText
        disableTypography
        primary={isSearchAttachOpen ? `${typeCode} ${isRequired}` : `${title} ${isRequired}`}
        secondary={
          !isSearchAttachOpen && (
            <Typography variant="caption" sx={{ display: 'inline', ...(title && { pl: 1 }) }}>
              {sub}
            </Typography>
          )
        }
      />
      {!isSearchAttachOpen && <StatusChip label={label} color={labelColor} size="small" />}
    </ListItemButton>
  );
}

const getCustomButton = (
  dateStart: string,
  dateEnd: string,
  status: string,
  consentOption: ConsentOption
) => {
  const period = { start: dateStart, end: dateEnd } as Period;
  switch (lowerCase(status)) {
    case 'active':
      return {
        title: consentOption.label,
        sub: period ? getFormattedDatePeriod(period) : '',
        type: consentOption.code,
        icon: <CheckCircle htmlColor="#00ab55" color="primary" />,
        label: 'Completed',
        labelColor: 'success' as LabelColor,
      };
    case 'proposed':
      return {
        title: consentOption.label,
        sub: period ? getFormattedDatePeriod(period) : '',
        type: consentOption.code,
        icon: <Pending htmlColor="#00b8d9" />,
        label: 'In Progress',
        labelColor: 'progress' as LabelColor,
      };
    case 'draft':
      return {
        title: consentOption.label,
        sub: period ? getFormattedDatePeriod(period) : '',
        type: consentOption.code,
        icon: <Pending htmlColor="#00b8d9" />,
        label: 'In Progress',
        labelColor: 'progress' as LabelColor,
      };
    default:
      return {
        title: consentOption.label,
        sub: '',
        type: consentOption.code,
        icon: <PlayCircleFilledRounded />,
        label: 'Not Started',
        labelColor: 'default' as LabelColor,
      };
  }
};

export const getSelectOptionCustomButton = (
  resource: ResourceWithIncludedResources<WrappedConsent>[],
  providerOption: ConsentOption[]
) => {
  const customButtonArray = providerOption
    ?.map((option: ConsentOption) => {
      const resourceByRole =
        resource?.find(
          (item: ResourceWithIncludedResources<WrappedConsent>) =>
            item?.resource?.category?.[0]?.coding?.[0]?.code === option?.code
        ) ?? null;
      return getCustomButton(
        resourceByRole?.resource?.provision?.period?.start ?? '',
        resourceByRole?.resource?.provision?.period?.end ?? '',
        resourceByRole?.resource?.status ?? '',
        option
      );
    })
    .flat();
  return customButtonArray;
};
