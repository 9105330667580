import CustomModal, { CustomModalBasicProps, GridItem } from 'src/components/CustomModal';
import SearchMember, { onSelectResource } from './SearchMember';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { Grid } from '@mui/material';

export interface SearchMemberModalProps extends CustomModalBasicProps {
  onSelectResource: onSelectResource;
  onCancel: Function;
  patient?: WrappedPatient;
  taskOwnerOptionsByConfig?: any;
}

const title = 'Update Owner';

const SearchMemberModal = ({
  onSelectResource,
  onCancel,
  patient,
  taskOwnerOptionsByConfig,
  ...modalProps
}: SearchMemberModalProps) => (
  <CustomModal
    title={title}
    breadcrumbs={['List of Tasks', title]}
    showSaveButton={false}
    onClose={() => {
      onCancel();
    }}
    onCancel={onCancel}
    {...modalProps}
  >
    <Grid sx={{ marginTop: 3 }} container>
      <GridItem xs={12}>
        <SearchMember
          showMemberTypeField={true}
          patient={patient as WrappedPatient}
          onSelectResource={onSelectResource}
          taskOwnerOptionsByConfig={taskOwnerOptionsByConfig}
        />
      </GridItem>
    </Grid>
  </CustomModal>
);

export default SearchMemberModal;
